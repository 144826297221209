import React from "react";
import { FaArrowRight } from "react-icons/fa";
import "../cgelex.css";

const TermsConditions = () => {
  return (
    <div
      className="container"
      style={{ marginTop: "50px", marginBottom: "50px" }}
    >
      <h3 className="mb-2">Terms and conditions</h3>
      <p
        className="mb-2"
        style={{
          fontWeight: "bold",
          fontSize: "15px",
          lineHeight: "25px",
          color: "black",
        }}
      >
        Welcome to cgelex.com! This website is owned and operated by CG
        Electronics. By accessing or using this website, you agree to comply
        with and be bound by the following terms and conditions. Please read
        these terms carefully before using our website.
      </p>
      <ol
        className="tnc"
        style={{ fontSize: "15px", lineHeight: "25px", color: "black" }}
      >
        <li className="mb-2">
          <span style={{ fontWeight: "bold" }}>Informative Website: </span>{" "}
          cgelex.com is an informative website designed to facilitate customers
          by providing information about CG Electronics' products, services, and
          promotions. It is not an e-commerce platform.
        </li>
        <li className="mb-2">
          <span style={{ fontWeight: "bold" }}>Ownership and Rights: </span> CG
          Electronics owns and reserves all rights to the content, design, and
          functionality of cgelex.com. All materials on this website, including
          but not limited to text, images, graphics, logos, and videos, are
          protected by copyright and other intellectual property laws.
        </li>
        <li className="mb-2">
          <span style={{ fontWeight: "bold" }}>Information Processing: </span>
          The purpose of cgelex.com is to process the information collected from
          customers to enhance secondary sales across CG Electronics' verticals.
          By providing your information on this website, you consent to its
          processing in accordance with our privacy policy.
        </li>
        <li className="mb-2">
          <span style={{ fontWeight: "bold" }}>Accuracy of Information: </span>{" "}
          While we strive to provide accurate and up-to-date information on
          cgelex.com, we do not warrant the completeness, accuracy, reliability,
          or timeliness of any information available on this website. You agree
          to use the information provided at your own risk.
        </li>
        <li className="mb-2">
          <span style={{ fontWeight: "bold" }}>Third-Party Links: </span>
          cgelex.com may contain links to third-party websites or resources.
          These links are provided for convenience only, and CG Electronics does
          not endorse or control the content of any third-party websites. We are
          not responsible for the availability or accuracy of any external sites
          linked to from cgelex.com.
        </li>

        <li className="mb-2">
          <span style={{ fontWeight: "bold" }}>Limitation of Liability: </span>{" "}
          In no event shall CG Electronics or its affiliates be liable for any
          direct, indirect, incidental, special, or consequential damages
          arising out of or in any way connected with your use of cgelex.com,
          even if advised of the possibility of such damages including any
          monetary transactions.
        </li>
        <li className="mb-2">
          <span style={{ fontWeight: "bold" }}>Changes to Terms: </span> CG
          Electronics reserves the right to modify or update these terms and
          conditions at any time without prior notice. It is your responsibility
          to review these terms periodically for changes.
        </li>
      </ol>
      <span
        style={{
          fontSize: "15px",
          lineHeight: "25px",
          color: "black",
        }}
      >
        By using cgelex.com, you agree to these terms and conditions. If you do
        not agree with any part of these terms, please do not use our website.
      </span>
      <h5 style={{ fontWeight: "bold", marginTop: "1rem" }}>Exchange</h5>
      <ol
        // class="tnc"
        style={{ fontSize: "15px", lineHeight: "25px", color: "black" }}
      >
        <li className="mb-2">
          This exchange e-coupon generated by CGELEX.COM is valid for a period
          of 10 days from the date of e-coupon generated by CGELEX.COM. E-coupon
          consists of the QR code with the information of customer, pre-owned
          product and new product selected by customer.
        </li>
        <li className="mb-2">
          The information provided in the checklist is presumed accurate;
          however, the company reserves the right to validate it in accordance
          with their terms and policy.
        </li>
        <li className="mb-2">
          This exchange e-coupon generated by CGELEX.COM is valid for a period
          of 10 days from the date of e-coupon generated by CGELEX.COM. E-coupon
          consists of the QR code with the information of customer, pre-owned
          product and new product selected by customer.
        </li>
        <li className="mb-2">
          If the pre-owned product is picked up but the customer decides not to
          proceed with an exchange, the company will arrange for re-delivery of
          the pre-owned product to the customer. However, in such cases, the
          customer will be responsible for bearing the pickup and re-delivery
          charges.
        </li>
        <li className="mb-2">
          If a pre-owned product is picked up, the company is not liable to
          provide any cash refund under any circumstances.
        </li>
        <li>
          The company bears no responsibility for any transit damage that may
          occur to the pre-owned product during re-delivery.
        </li>
      </ol>
      <p
        style={{
          fontWeight: "bold",
          fontSize: "15px",
          lineHeight: "25px",
          color: "black",
        }}
      >
        The company bears no responsibility for any transit damage that may
        occur to the pre-owned product during re-delivery.
      </p>
      <h5 style={{ fontWeight: "bold", marginTop: "1rem" }}>EMI</h5>
      <ol
        // class="tnc"
        style={{ fontSize: "15px", lineHeight: "25px", color: "black" }}
      >
        <li className="mb-2">
          {" "}
          This is the EMI calculator and not the final Quotation.
        </li>
        <li className="mb-2">
          {" "}
          Need to visit the shop location for EMI Process.
        </li>
        <li className="mb-2">
          Partner Bank or Finance Institute have the final decision on the EMI
          amount approval.
        </li>
        <li className="mb-2">
          Must need to provide the valid documents, as per the request and
          requirement by Finance institute.
        </li>
        <li
          style={{
            fontWeight: "bold",
            fontSize: "15px",
            lineHeight: "25px",
            color: "black",
          }}
        >
          The availability of the product(s) selected by the user of CG ELEX is
          conditional upon the inventory at the chosen store. Availability may
          fluctuate at the chosen store by the user.{" "}
        </li>
      </ol>
    </div>
  );
};

export default TermsConditions;
