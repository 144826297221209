import {Dispatcher} from "flux";

export const Event = {
    dispatcher: new Dispatcher(),

    EVENT_PROGRESS: "progress",

    progress(active) {
        Event.dispatcher.dispatch({
            type: Event.EVENT_PROGRESS,
            active: active
        })
    },
};