import EmiCodeModal from "components/EmiCodeModal";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const EmiCode = (props) => {
  const [data, setData] = useState(null);
  const Navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (location.state) {
      setData(location.state.data);
    } else {
      Navigate("/");
    }
  }, []);
  return (
    <>
      <EmiCodeModal data={data} />
    </>
  );
};

export default EmiCode;
