import Storage from "./storage";

class Data {
  static get BASE_URL() {
    // return "http://192.168.11.19/digi_live/public/";
    // return "http://localhost/digi_live/public/";
    // return "http://192.168.10.23/digital/public/";
    // return "http://localhost/cg_digital/version2/";
    return "https://www.cgdigital.com.np/";
    // return "http://www.demo.cgdigital.com.np/";
  }

  static get API_BASE_URL() {
    return this.BASE_URL + "api/";
  }

  static get API_BASE_URL_CRM() {
    return "https://crm.cgelectronics.com.np/ism/api/";
  }

  static get IMAGE_BASE_URL() {
    return this.BASE_URL + "uploads/images/";
  }

  static get API_HEADER() {
    return {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + Storage.getAccessToken(),
    };
  }

  static get API_HEADER_WO_CONTENT_TYPE() {
    const header = Data.API_HEADER;
    delete header["Content-Type"];
    return header;
  }

  static get APP_NAME() {
    return "CG Digital";
  }

  static get DEFAULT_IMAGE() {
    return "placeholder.png";
  }
}

export default Data;
