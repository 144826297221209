import React, { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import Data from "./utils/data";
import api from "./utils/api";
const BrandAreaOne = () => {
  const [logos, setlogos] = useState([]);

  function fetchLogos() {
    fetch(Data.API_BASE_URL + "exchange/active-brands", {
      method: "GET",
    })
      .then(api.handleResponseAndError)
      .then((response) => {
        setlogos(response);
        // setIsloading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    fetchLogos();
  }, []);
  return (
    <>
      {logos.length > 0 ? (
        <div className="about-area bg-gray pt-5 pb-5">
          <div className="container">
            <div className="client-slider">
              {/* <Marquee gradient={false}>
                <>
                  {logos.map((item, index) => {
                    return (
                      <div key={index} className="thumb">
                        <img src={item.image} alt="img" />
                      </div>
                    );
                  })}
                </>
              </Marquee> */}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {/* <Marquee gradient={false}>
   //   <div className='thumb'>
   //     <img src='assets/img/lglogo.png' alt='img' />
   //   </div>
   //   <div className='thumb'>
   //     <img src='assets/img/lglogo.png' alt='img' />
   //   </div>
   //   <div className='thumb'>
   //     <img src='assets/img/lglogo.png' alt='img' />
   //   </div>
   //   <div className='thumb'>
   //     <img src='assets/img/lglogo.png' alt='img' />
   //   </div>
   //   <div className='thumb'>
   //     <img src='assets/img/lglogo.png' alt='img' />
   //   </div>
   //   <div className='thumb'>
   //     <img src='assets/img/lglogo.png' alt='img' />
   //   </div>
   // </Marquee> */}
    </>
  );
};

export default BrandAreaOne;
