import React, { useState } from 'react';
import Modal from 'react-modal';
import test_qr from "../test_qr.png";


const CouponCodeModal = (props) => {


      
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      zIndex: '99999',
      width: '80%',
      height:'80%',
    },
    overlay: {zIndex: 1000}
  };
  return (
          <div className='container mb-5 mt-5 couponcontainer'>
   
                    <div>

                 <div className='column' style={{display:"flex",flexDirection:"column",textAlign:"center",justifyContent:"center",alignItems:"center"}}>
                    <h3>Congratulations!</h3>
                    <p style={{color:"black",fontWeight:"bold"}}>Registration successful.</p>

                    {props.loyaltyRegistration == true ? (<>
                      <p style={{color:"red", fontSize:"12px"}}>(Voucher will be sent on mail after verification.)</p>
                    </>) : ""}
                   

                    {props.warrantyRegistration == true ? (<>
                      <p style={{color:"red", fontSize:"12px"}}>(Our team will verify the submitted data and you will get SMS notification very soon.)</p>
                    </>) : ""}

                 </div>
                 <br/>
                      
                    </div>
                    
                          
      
    </div>
  )
}

export default CouponCodeModal
