import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import About from "./pages/About";
import Blog from "./pages/Blog";
import BlogDetails from "./pages/BlogDetails";
import CaseStudyDetails from "./pages/CaseStudyDetails";
import Contact from "./pages/Contact";
import HomeFour from "./pages/HomeFour";
import HomeOne from "./pages/HomeOne";
import HomeThree from "./pages/HomeThree";
import HomeTwo from "./pages/HomeTwo";
import Service from "./pages/Service";
import ServiceDetails from "./pages/ServiceDetails";
import Team from "./pages/Team";
import TeamDetails from "./pages/TeamDetails";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import ScrollToTop from "react-scroll-to-top";
import HomeFive from "./pages/HomeFive";
import Exchange from "./components/Exchange/Index";
import NavbarThree from "./components/NavbarThree";
import FooterThree from "./components/FooterThree";
import NotFound from "./pages/NotFound";
import SubmittedPage from "./pages/SubmittedPage";
import Store from "./pages/Store";
import TermsConditions from "./pages/TermsConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import SearchResults from "components/SearchResults";
import Search from "pages/Search";
import ExchangeCode from "pages/ExchangeCode";
import CouponCode from "pages/CouponCode";
import CustomerRegistration from "pages/CustomerRegistration";
import Emi from "pages/Emi";
import EmiSuccess from "pages/EmiCode";
import EmiCode from "pages/EmiCode";
import TermsnConditionsEMI from "./pages/TermsnConditionsEMI";
import ElexInsurance from "pages/ElexInsurance";
import ElexInsuranceSubmit from "pages/ElexInsuranceSubmit";

function App() {
  useEffect(() => {
    AOS.init({
      offset: 0,
      easing: "ease",
      once: true,
    });
    AOS.refresh();
  }, []);
  return (
    <BrowserRouter>
      <NavbarThree />
      <Routes>
        <Route exact path="/" element={<HomeFour />} />
        {/* <Route exact path='/index-2' element={<HomeTwo />} />
        <Route exact path='/index-3' element={<HomeThree />} /> */}

        {/* <Route exact path='/index-4' element={<HomeFour />} />
        <Route exact path='/index-5' element={<HomeFive />} /> */}
        <Route exact path="/about" element={<About />} />
        {/* <Route exact path='/blog' element={<Blog />} /> */}
        <Route exact path="/store" element={<Store />} />
        {/* <Route exact path='/blog-details' element={<BlogDetails />} /> */}
        <Route exact path="/exchange" element={<Exchange />} />
        {/* <Route
          exact
          path='/case-study-details'
          element={<CaseStudyDetails />}
        /> */}
        <Route exact path="/contact" element={<Contact />} />
        {/* <Route exact path='/service' element={<Service />} />
        <Route exact path='/service-details' element={<ServiceDetails />} />
        <Route exact path='/team' element={<Team />} />
        <Route exact path='/team-details' element={<TeamDetails />} /> */}
        <Route path="*" element={<NotFound />} />
        <Route path="/submittedpage" element={<SubmittedPage />} />
        <Route path="/terms-and-conditions" element={<TermsConditions />} />
        <Route
          path="/terms-and-conditions-emi"
          element={<TermsnConditionsEMI />}
        />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route exact path="/search-results/:search_data" element={<Search />} />
        <Route path="/request-successful" element={<ExchangeCode />} />
        <Route path="/registration-successful" element={<CouponCode />} />
        <Route
          path="/customer-registration"
          element={<CustomerRegistration />}
        />

        <Route path="/emi" element={<Emi />} />
        <Route path="/emi-success" element={<EmiCode />} />
        {/* <Route path="/elex-insurance" element={<ElexInsurance />} />
        <Route
          path="/elex-insurance-success"
          element={<ElexInsuranceSubmit />}
        /> */}
      </Routes>
      <FooterThree />
      <ScrollToTop smooth color="#246BFD" />
    </BrowserRouter>
  );
}

export default App;
