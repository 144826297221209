import React from 'react'
import StoreOne from '../components/StoreOne'
import Breadcrumb from '../components/Breadcrumb'

const Store = () => {
  return (
    <div>

{/* <Breadcrumb title={"Store"} /> */}
      <StoreOne />
    </div> 
  )
}

export default Store
