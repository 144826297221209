import { Key } from "./key";
import Util from "./util";

export default {
  get(key) {
    return localStorage.getItem(key);
  },

  save(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  },

  delete(key) {
    localStorage.removeItem(key);
  },

  contains(key) {
    return key in localStorage;
  },

  login(user) {
    this.save(Key.USER, user);
  },

  logout() {
    this.delete(Key.USER);
  },

  isLoggedIn() {
    return this.contains(Key.USER);
  },

  getUser() {
    return JSON.parse(this.get(Key.USER));
  },

  getUserName() {
    return this.getUser()["name"];
  },

  getLoyality() {
    return this.getUser()["loyality"];
  },

  getUserAvatar() {
    return Util.imageToUrl(this.getUser()["avatar"]);
  },

  getAccessToken() {
    return this.isLoggedIn() ? this.getUser()["token"] : "";
  },
  checkPopUp() {
    if (this.get("pop_up")) {
      var pop_up_time = new Date(this.get("pop_up"));
      var today = new Date();
      var diffMs = today - pop_up_time;
      var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);
      if (diffMins < 5) {
        return false;
      }
    }
    return true;
  },
  checkHomePopUp() {
    if(this.get('home_pop_up')){
      var home_pop_up_time = new Date(this.get('home_pop_up'));
      var today = new Date();
      var diffMs = (today - home_pop_up_time);
     var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);
      if(diffMins < 5){
        return false;
      }
    }
    return true;
  },
  updateUser(profile) {
    const user = this.getUser();
    user["name"] = profile["name"];
    user["avatar"] = profile["avatar"];
    this.save(Key.USER, user);
  },
  addToCompare(product) {
    this.save("compare-product", product);
  },
  getCompareProducts() {
    return this.get("compare-product")
      ? JSON.parse(this.get("compare-product"))
      : [];
  },
};
