import { FaPlus } from "react-icons/fa";
import React, { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { useInput } from "../components/hooks/inputHook";
import Data from "../components/utils/data";
import api from "../components/utils/api";
import { Navigate, useNavigate } from "react-router-dom";

const ContactAreaFour = () => {
  const [isLoading, setIsloading] = useState(false);

  const { value: name, bind: bindName, setValue: setName } = useInput("");
  const { value: gender, bind: bindGender, setValue: setGender } = useInput("");
  const { value: age, bind: bindAge, setValue: setAge } = useInput("");
  const { value: email, bind: bindEmail, setValue: setEmail } = useInput("");
  const {
    value: address,
    bind: bindAddress,
    setValue: setAddress,
  } = useInput("");
  const {
    value: contact,
    bind: bindContact,
    setValue: setContact,
  } = useInput("");
  const [checkDiscount, setCheckDiscount] = useState(false);
  const [checkWarranty, setCheckWarranty] = useState(false);
  const [file, setFile] = useState("");
  const [base64, setBase64] = useState("");

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessageName, setErrorMessageName] = useState("");
  const [errorMessageGender, setErrorMessageGender] = useState("");
  const [errorMessageEmail, setErrorMessageEmail] = useState("");
  const [errorMessageContactNumber, setErrorMessageContactNumber] =
    useState("");
  const [errorMessageAddress, setErrorMessageAddress] = useState("");
  const [errorMessageAge, setErrorMessageAge] = useState("");
  const [errorMessageFile, setErrorMessageFile] = useState("");

  const Navigate = useNavigate();
  const checkboxHandlerDiscountCode = () => {
    setCheckDiscount(!checkDiscount);
  };
  const checkboxHandlerWarranty = () => {
    setCheckWarranty(!checkWarranty);
  };
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      setBase64(reader.result.replace(/^data:image\/\w+;base64,/, ""));
    };
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setErrorMessageName("");
    setErrorMessageEmail("");
    setErrorMessageContactNumber("");
    setErrorMessageAddress("");
    setSuccessMessage("");
    setErrorMessageFile("");
    setErrorMessageAge("");

    let formError = false;

    const pattern = /[^a-zA-Z\s.]/g;
    if (name == "") {
      setErrorMessageName("Please enter name");
      formError = true;
    } else if (pattern.test(name)) {
      setErrorMessageName("Name should not contain special characters.");
      formError = true;
    }

    const emailRegExp = /\S+@\S+\.\S+/;
    if (email == "") {
      setErrorMessageEmail("Please enter email");
      formError = true;
    }
    if (email != "" && !emailRegExp.test(email)) {
      setErrorMessageEmail("Please enter valid email address.");
      formError = true;
    }

    if (contact == "") {
      setErrorMessageContactNumber("Please enter mobile number");
      formError = true;
    } else if (contact.length != 10) {
      setErrorMessageContactNumber("Please enter 10 digit mobile number.");
      formError = true;
    }
    if (gender == "") {
      setErrorMessageGender("Please select gender");
      formError = true;
    }

    if (address == "") {
      setErrorMessageAddress("Please enter address");
      formError = true;
    }

    if ((checkDiscount == true || checkWarranty == true) && file == "") {
      setErrorMessageFile("Please select warranty file.");
      formError = true;
    }

    if (file != "" && !file.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      setErrorMessageFile("Please select valid image file.");
      formError = true;
    }

    if (file != "") {
      if (file.size / 1024 > 4096) {
        setErrorMessageFile("Please select file of size 4MB Max.");
        formError = true;
      }
    }

    if (formError == false) {
      let url = new URL(
        Data.API_BASE_URL + "exchange/post-customer-registration"
      );
      setIsloading(true);
      fetch(url, {
        method: "POST",
        headers: Data.API_HEADER,
        body: JSON.stringify({
          name: name,
          email: email,
          address: address,
          contact: contact,
          gender: gender,
          age: age,
          attachment: base64,
          loyalty_registration: checkDiscount,
          warranty_registration: checkWarranty,
        }),
      })
        .then(api.handleResponseAndError)
        .then((response) => {
          if (response.statusCode == 200) {
            // window.location = response.url;
            setName("");
            setAddress("");
            setEmail("");
            setContact("");
            setGender("");
            setAge("");
            setFile("");
            setSuccessMessage(response.message);

            Navigate("/registration-successful", {
              state: {
                loyalty_registration: response.loyalty_registration,
                warranty_registration: response.warranty_registration,
              },
            });
            setIsloading(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  return (
    <React.Fragment>
      {isLoading ? (
        <div
          classNameName="container"
          style={{ textAlign: "center", padding: "100px 0px" }}
        >
          <ClipLoader
            css={{ margin: "0 auto" }}
            size={150}
            //size={"150px"} this also works
            color={"#123abc"}
            loading={isLoading}
          />
        </div>
      ) : (
        <>
          {/*================= counter area start {/*=================*/}
          <div className="contact-area pd-top-90 pd-bottom-120">
            <div className="container">
              <div className="row">
                <div
                  className="col-lg-4 order-lg-end"
                  data-aos="fade-left"
                  data-aos-delay="200"
                  data-aos-duration="1500"
                >
                  <div className="about-thumb-inner">
                    {/* <img
                  className='animate-main-img'
                  src='assets/img/about/18.png'
                  alt='img'
                /> */}
                    <img
                      className="main-img"
                      src="assets/img/loyalty-reward.jpeg"
                      alt="img"
                    />
                  </div>
                </div>

                <div
                  className="col-lg-8 order-lg-first"
                  data-aos="fade-right"
                  data-aos-delay="200"
                  data-aos-duration="1500"
                >
                  <div className="section-title mb-0 mt-5 mt-lg-0">
                    <h2 className="title">
                      Customer <span>Registration</span>
                    </h2>
                    {/* <span className='sub-title'>Full Name</span> */}

                    <form className="mt-4">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="single-input-inner style-border">
                            <input
                              type="text"
                              placeholder="Name"
                              {...bindName}
                            />
                            {errorMessageName ? (
                              <>
                                <p style={{ color: "red" }}>
                                  {errorMessageName}
                                </p>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="single-input-inner style-border">
                            <select {...bindGender}>
                              <option default>Select Gender</option>
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                            </select>
                            {errorMessageGender ? (
                              <>
                                <p style={{ color: "red" }}>
                                  {errorMessageGender}
                                </p>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="single-input-inner style-border">
                            <input
                              type="number"
                              min="1"
                              placeholder="Age"
                              {...bindAge}
                            />
                            {errorMessageAge ? (
                              <>
                                <p style={{ color: "red" }}>
                                  {errorMessageAge}
                                </p>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="single-input-inner style-border">
                            <input
                              type="text"
                              placeholder="Address"
                              {...bindAddress}
                            />
                            {errorMessageAddress ? (
                              <>
                                <p style={{ color: "red" }}>
                                  {errorMessageAddress}
                                </p>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="single-input-inner style-border">
                            <input
                              type="number"
                              placeholder="Contact"
                              {...bindContact}
                            />
                            {errorMessageContactNumber ? (
                              <>
                                <p style={{ color: "red" }}>
                                  {errorMessageContactNumber}
                                </p>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="single-input-inner style-border">
                            <input
                              type="email"
                              placeholder="Email"
                              {...bindEmail}
                            />
                            {errorMessageEmail ? (
                              <>
                                <p style={{ color: "red" }}>
                                  {errorMessageEmail}
                                </p>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>

                        {/* <div className="col-lg-12">
                          <div className="single-input-inner style-border">
                            <input
                              type="checkbox"
                              id="warrantyReg"
                              name="checkbox"
                              value="1"
                              className="vivantadiscountcheckbox"
                              onChange={checkboxHandlerWarranty}
                            />
                            <label for="warrantyReg">
                              &nbsp;&nbsp;Register 3 years warranty for LG Tv.{" "}
                              <span style={{ color: "red", fontSize: "12px" }}>
                                (*Condition apply.)
                              </span>
                            </label>
                          </div>
                        </div> */}

                        {checkDiscount == true || checkWarranty == true ? (
                          <>
                            <div className="col-lg-12">
                              <div className="single-input-inner style-border">
                                <label
                                  className="warrantycardlabel"
                                  for="warrantycardupload"
                                >
                                  Upload Warranty Card to get{" "}
                                  <span>Vivanta Discount code. (4MB Max)</span>
                                </label>
                                <input
                                  type="file"
                                  id="warrantycardupload"
                                  className="vivantaupload"
                                  onChange={handleFileChange}
                                />
                                {errorMessageFile ? (
                                  <>
                                    <p style={{ color: "red" }}>
                                      {errorMessageFile}
                                    </p>
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}

                        <div className="col-12">
                          <a
                            className="btn btn-border-gray mt-0"
                            onClick={handleSubmit}
                          >
                            Register <FaPlus />
                          </a>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*{/*================= counter area end {/*=================*/}
        </>
      )}
    </React.Fragment>
  );
};

export default ContactAreaFour;
