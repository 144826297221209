import React, { useEffect, useState } from "react";
import {
  FaSearch,
  FaUser,
  FaYoutube,
  FaTwitter,
  FaExchangeAlt,
  FaCalculator,
  FaShieldAlt,
  FaGift,
} from "react-icons/fa";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import Data from "./utils/data";
import api from "./utils/api";
import newlogo from "../newlogo.png";
import logoelex from "../logoelex.png";
import accident from "../accident.png";

export const Logo = () => {
  return <img src={newlogo} alt="Logo" />;
};

const NavbarThree = ({ props }) => {
  const [active, setActive] = useState(false);
  const [searchShow, setSearchShow] = useState(false);
  // const[searchData, setSearchData] = useState([]);
  // const [searchQuery, setSearchQuery] = useState("");
  // const [searchString, setSearchString] = useState();
  const [searchData, setSearchData] = useState([]);
  const [search, setSearch] = useState("");
  const [showResults, setShowResults] = useState(false);
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState(null);

  const menuActive = () => {
    setActive(!active);
  };
  const isMediumScreen = window.innerWidth <= 1299 && window.innerWidth > 991;
  const searchActive = () => {
    setSearchShow(!searchShow);
  };

  const navigate = useNavigate();

  const onChangeSearch = (evt) => {
    setShowResults(false);
    let value = evt.target.value;
    setSearch(value);
  };

  const onClickSearch = (evt) => {
    evt.preventDefault();
    setShowResults(false);
    navigate("/search-results/" + search, {
      state: {
        search_keyword: search,
      },
    });
    setSearchShow(false);
    window.location.reload(true);
    return false;
  };

  const handleLinkClick = (url) => {
    window.open(url, "_blank");
  };

  let items = document.querySelectorAll(".menu-item-has-children > a");
  for (let i in items) {
    if (items.hasOwnProperty(i)) {
      items[i].onclick = function () {
        this.parentElement
          .querySelector(".sub-menu")
          .classList.toggle("active");
        this.classList.toggle("open");
      };
    }
  }

  return (
    <>
      {/* search popup start*/}
      <div
        className={searchShow ? "td-search-popup active" : "td-search-popup "}
        id="td-search-popup"
      >
        <form
          action="/"
          className="search-form"
          onSubmit={(evt) => {
            onClickSearch(evt);
          }}
        >
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Search....."
              onChange={onChangeSearch}
            />
          </div>
          <button type="submit" className="submit-btn" disabled={!search}>
            <FaSearch />
          </button>
        </form>
      </div>
      {/* search popup end*/}
      <div
        onClick={searchActive}
        className={searchShow ? "body-overlay active" : "body-overlay"}
        id="body-overlay"
      ></div>

      {/* top heading start*/}
      {/* <div className="topheading">
        <ul className="toplistings btn btn-border-base">
            
            <li className="toplistingslists"><a href="/customer-registration">Loyalty Club</a></li>
          </ul>
        
      </div> */}
      {/* <a href="https://cgagro.com.np/faqs/" class="floatingmenu">Loyalty Club</a> */}
      {/* ==================== Navbar Two Start ====================*/}
      <nav className="navbar navbar-area navbar-area-2 navbar-expand-lg bg-white">
        <div className="container nav-container custom-container">
          <div className="responsive-mobile-menu">
            <button
              onClick={menuActive}
              className={
                active
                  ? "menu toggle-btn d-block d-lg-none open mt-2"
                  : "menu toggle-btn d-block d-lg-none mt-2"
              }
              data-target="#itech_main_menu"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="icon-left" />
              <span className="icon-right" />
            </button>
          </div>
          <div className="logo logoname">
            {/* <Link to='/'> */}
            {/* <img src='assets/img/logoelex.png' alt='img' /> */}
            <a
              onClick={() => {
                window.location.href = "/";
              }}
              style={{ cursor: "pointer" }}
            >
              {window.location.pathname === "/exchange" ? (
                <img src={logoelex} alt={"logo"} />
              ) : (
                <img src={newlogo} alt={"logo"} />
              )}
            </a>
            {/* </Link> */}
          </div>

          <div className="nav-right-part nav-right-part-mobile md-display-none">
            <span className="search-bar-btn" onClick={searchActive}>
              <FaSearch />
            </span>
          </div>
          <div
            className={
              active
                ? "collapse navbar-collapse sopen"
                : "collapse navbar-collapse for-tablet-responsive"
            }
            id="itech_main_menu"
            style={{ paddingLeft: "20px" }}
          >
            <ul className="navbar-nav menu-open text-lg-end centerizeondesktop md-display-none">
              <li>
                <a
                  onClick={() => {
                    window.location.href = "/";
                  }}
                  className="main_navs"
                >
                  Home
                </a>
              </li>
              <li>
                {/* <Link to='/about'>About Us</Link> */}
                <a
                  onClick={() => {
                    window.location.href = "/about";
                  }}
                  className="main_navs"
                >
                  About
                </a>
                {/* <a href='/about'>About Us</a> */}
              </li>
              {/* <li className='menu-item-has-children'>
                <a href='#'>Product</a>
                <ul className='sub-menu'>
                  <li>
                    <Link to='#'>Product 1</Link>
                  </li>
                  <li>
                    <Link to='#'>Product 2</Link>
                  </li>
                  <li>
                    <Link to='#'>Product 3</Link>
                  </li>
                  <li>
                    <Link to='#'>Product 4</Link>
                  </li>
                </ul>
              </li> */}
              <li>
                {/* <Link to='/store'>Store</Link> */}
                <a
                  onClick={() => {
                    window.location.href = "/store";
                  }}
                  className="main_navs"
                >
                  Store
                </a>
              </li>
              {/* <li className='menu-item-has-children'>
                <Link href='#'>Support</Link>
                <ul className='sub-menu'>
                  <li>
                    <Link to='#'>Product 1</Link>
                  </li>
                  <li>
                    <Link to='#'>Product 2</Link>
                  </li>
                  <li>
                    <Link to='#'>Product 3</Link>
                  </li>
                  <li>
                    <Link to='#'>Product 4</Link>
                  </li>
                </ul>
              </li> */}
              <li>
                {/* <Link to='/contact'>contact</Link> */}
                <a
                  onClick={() => {
                    window.location.href = "/contact";
                  }}
                  className="main_navs"
                >
                  contact
                </a>
              </li>

              <li className="hideondesktop">
                {/* <HashLink className='btn btn-border-base' to='/exchange'>
              Exchange <FaExchangeAlt />
            </HashLink> */}
                {/* <a
                  onClick={() => {
                    window.location.href = "/customer-registration";
                  }}
                  className="main_navs btn btn-border-base"
                  style={{ marginBottom: "10px" }}
                >
                  Loyalty Club
                  <FaUser />
                </a> */}
                <a
                  onClick={() => {
                    window.location.href = "/exchange";
                  }}
                  className="main_navs btn btn-border-base"
                  style={{ marginBottom: "10px" }}
                >
                  Exchange <FaExchangeAlt />
                </a>
                <a
                  onClick={() => {
                    window.location.href = "/emi";
                  }}
                  className="main_navs btn btn-border-base"
                  style={{ marginBottom: "10px" }}
                >
                  EMI <FaCalculator />
                </a>
                {/* <a
                  onClick={() => {
                    window.location.href = "/elex-insurance";
                  }}
                  className="main_navs btn btn-border-base"
                  style={{ marginBottom: "10px" }}
                >
                  Insurance <FaShieldAlt />
                </a> */}
                {/* <a
                  onClick={() =>
                    handleLinkClick(
                      "https://docs.google.com/forms/d/1q9235PX-5NzOE4D0XoT5E9xC7-FK6UxOxUJZy5ZmEa0/viewform?edit_requested=true&pli=1"
                    )
                  }
                  className="main_navs btn btn-border-base"
                  style={{ visibility: "visible !important" }}
                >
                  LG Lucky Draw <FaGift />
                </a> */}
              </li>
            </ul>
          </div>
          <div className="nav-right-part nav-right-part-desktop d-lg-inline-flex align-item-center md-display-none">
            {/* <FaFacebook className='sociallogins' />
            <FaYoutube className='sociallogins'  />
            <FaTwitter className='sociallogins'  /> */}

            <div className="nav-right-part desktopsearch">
              <span
                className={
                  searchShow ? "search-bar-btn-none" : "search-bar-btn"
                }
                onClick={searchActive}
              >
                <FaSearch />
              </span>
            </div>
            {/* <a
              onClick={() => {
                window.location.href = "/customer-registration";
              }}
              className="main_navs btn btn-border-base navBtn"
              style={{ marginRight: "15px" }}
            >
              Loyalty Club <FaUser className="adjustsvg" />
            </a> */}
            <a
              onClick={() => {
                window.location.href = "/exchange";
              }}
              className="main_navs btn btn-border-base navBtn"
              style={{ marginRight: "15px" }}
            >
              Exchange <FaExchangeAlt className="adjustsvg" />
            </a>
            <a
              onClick={() => {
                window.location.href = "/emi";
              }}
              className="main_navs btn btn-border-base navBtn"
              style={{ marginRight: "15px" }}
            >
              EMI <FaCalculator className="adjustsvg" />
            </a>
            {/* <a
              onClick={() => {
                window.location.href = "/elex-insurance";
              }}
              className="main_navs btn btn-border-base navBtn"
              style={{ marginRight: "15px" }}
            >
              Insurance <FaShieldAlt className="adjustsvg" />
            </a> */}
            {/* <a
              onClick={() =>
                handleLinkClick(
                  "https://docs.google.com/forms/d/1q9235PX-5NzOE4D0XoT5E9xC7-FK6UxOxUJZy5ZmEa0/viewform?edit_requested=true&pli=1"
                )
              }
              className="main_navs btn btn-border-base navBtn"
              style={{ visibility: "visible !important" }}
            >
              LG Lucky Draw <FaGift className="adjustsvg" />
            </a> */}
          </div>

          {/* for small screen size */}
          <div className="md-nav-lists">
            <div className="nav-right-part nav-right-part-mobile">
              <span className="search-bar-btn" onClick={searchActive}>
                <FaSearch />
              </span>
            </div>
            <div
              className={
                active
                  ? "navbar-collapse sopen"
                  : "navbar-collapse for-tablet-responsive"
              }
              id="itech_main_menu"
              style={{ paddingLeft: "20px" }}
            >
              <ul className="navbar-nav menu-open">
                <li>
                  <a
                    onClick={() => {
                      window.location.href = "/";
                    }}
                    className="main_navs"
                  >
                    Home
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      window.location.href = "/about";
                    }}
                    className="main_navs"
                  >
                    About
                  </a>
                </li>

                <li>
                  <a
                    onClick={() => {
                      window.location.href = "/store";
                    }}
                    className="main_navs"
                  >
                    Store
                  </a>
                </li>

                <li>
                  <a
                    onClick={() => {
                      window.location.href = "/contact";
                    }}
                    className="main_navs"
                  >
                    contact
                  </a>
                </li>

                <li className="hideondesktop">
                  {/* <a
                    onClick={() => {
                      window.location.href = "/customer-registration";
                    }}
                    className="main_navs btn btn-border-base"
                    style={{ marginBottom: "10px" }}
                  >
                    Loyalty Club
                    <FaUser />
                  </a> */}
                  <a
                    onClick={() => {
                      window.location.href = "/exchange";
                    }}
                    className="main_navs btn btn-border-base"
                    style={{ marginBottom: "10px" }}
                  >
                    Exchange <FaExchangeAlt />
                  </a>
                  <a
                    onClick={() => {
                      window.location.href = "/emi";
                    }}
                    className="main_navs btn btn-border-base"
                    style={{ marginBottom: "10px" }}
                  >
                    EMI <FaCalculator />
                  </a>
                  {/* <a
                    onClick={() => {
                      window.location.href = "/elex-insurance";
                    }}
                    className="main_navs btn btn-border-base"
                    style={{ marginBottom: "10px" }}
                  >
                    Insurance <FaShieldAlt />
                  </a> */}
                  {/* <a
                    onClick={() =>
                      handleLinkClick(
                        "https://docs.google.com/forms/d/1q9235PX-5NzOE4D0XoT5E9xC7-FK6UxOxUJZy5ZmEa0/viewform?edit_requested=true&pli=1"
                      )
                    }
                    className="main_navs btn btn-border-base"
                    style={{ visibility: "visible !important" }}
                  >
                    LG Lucky Draw <FaGift />
                  </a> */}
                </li>
              </ul>
            </div>
            <div className="nav-right-part nav-right-part-desktop d-lg-inline-flex align-item-center">
              <div className="nav-right-part desktopsearch">
                <span
                  className={
                    searchShow ? "search-bar-btn-none" : "search-bar-btn"
                  }
                  onClick={searchActive}
                >
                  <FaSearch />
                </span>
              </div>
              {/* <a
                onClick={() => {
                  window.location.href = "/customer-registration";
                }}
                className="main_navs btn btn-border-base navBtn"
                style={{ marginRight: "15px" }}
              >
                Loyalty Club <FaUser className="adjustsvg" />
              </a> */}
              <a
                onClick={() => {
                  window.location.href = "/exchange";
                }}
                className="main_navs btn btn-border-base navBtn"
                style={{ marginRight: "15px" }}
              >
                Exchange <FaExchangeAlt className="adjustsvg" />
              </a>
              <a
                onClick={() => {
                  window.location.href = "/emi";
                }}
                className="main_navs btn btn-border-base navBtn"
                style={{ marginRight: "15px" }}
              >
                EMI <FaCalculator className="adjustsvg" />
              </a>
              {/* <a
                onClick={() => {
                  window.location.href = "/elex-insurance";
                }}
                className="main_navs btn btn-border-base navBtn"
                style={{ marginRight: "15px" }}
              >
                Insurance <FaShieldAlt className="adjustsvg" />
              </a> */}
              {/* <a
                onClick={() =>
                  handleLinkClick(
                    "https://docs.google.com/forms/d/1q9235PX-5NzOE4D0XoT5E9xC7-FK6UxOxUJZy5ZmEa0/viewform?edit_requested=true&pli=1"
                  )
                }
                className="main_navs btn btn-border-base navBtn"
                style={{ visibility: "visible !important" }}
              >
                LG Lucky Draw <FaGift className="adjustsvg" />
              </a> */}
            </div>
          </div>
        </div>
      </nav>
      {/* ==================== Navbar Two end ====================*/}
    </>
  );
};

export default NavbarThree;
