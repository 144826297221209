import Data from "components/utils/data";
import React from "react";
import { useState } from "react";
import api from "../components/utils/api";
import { useEffect } from "react";
import { HashLink } from "react-router-hash-link";
import Modal from "react-modal";
import { FaTimes, FaEye } from "react-icons/fa";
import { Tab, Col, Nav, Form } from "react-bootstrap";
import { ClipLoader } from "react-spinners";
import { useInput } from "components/hooks/inputHook";
import { Navigate, useNavigate } from "react-router-dom";

const Emi = () => {
  const [subCategoryTypes, setSubCategoryTypes] = useState([]);
  const [subCategoryType, setSubCategoryType] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [brandProducts, setBrandProducts] = useState([]);
  const [brandProduct, setBrandProduct] = useState(null);
  const [brand, setBrand] = useState([]);
  const [brands, setBrands] = useState([]);
  const [currentTab, setCurrentTab] = useState("first");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isLoading, setIsloading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [bank, setBank] = useState(null);
  const [banks, setBanks] = useState([]);
  const [emiPeriod, setEmiPeriod] = useState(null);
  const [downPaymentAmount, setDownPaymentAmount] = useState(0);
  const [emiInstallment, setEmiInstallment] = useState(0);
  // const [emiLocation, setEmiLocation] = useState(null);
  // const [emiLocations, setEmiLocations] = useState([]);
  // const [emiDealer, setEmiDealer] = useState(null);
  // const [emiDealers, setEmiDealers] = useState([]);
  const [agree, setAgree] = useState(false);
  // const [showDes, setShowDes] = useState(false);
  const {
    value: fullName,
    bind: bindFullName,
    setValue: setFullName,
  } = useInput("");
  const {
    value: phoneNumber,
    bind: bindPhoneNumber,
    setValue: setPhoneNumber,
  } = useInput("");
  const { value: email, bind: bindEmail, setValue: setEmail } = useInput("");
  const {
    value: address,
    bind: bindAddress,
    setValue: setAddress,
  } = useInput("");

  // const [successMessage, setSuccessMessage] = useState("");
  const [errorMessageFullname, setErrorMessageFullname] = useState("");
  const [errorMessageEmail, setErrorMessageEmail] = useState("");
  const [errorMessagePhone, setErrorMessagePhone] = useState("");
  const [errorMessageAddress, setErrorMessageAddress] = useState("");
  // const [errorMessageLocation, setErrorMessageLocation] = useState("");
  // const [errorMessageDealer, setErrorMessageDealer] = useState("");

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      zIndex: "99999",
      width: "80%",
      height: "80%",
    },
    overlay: { zIndex: 1000 },
  };

  const Navigate = useNavigate();

  const handleSelect = (eventKey) => {
    setCurrentTab("second");
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const handleBankClick = (item) => {
    setSelectedImage(item.id);
    setEmiPeriod(null);
    // setEmiLocation(null);
    // setEmiDealer(null);
    setBank(item);
  };

  const handlePeriodClick = (item) => {
    setEmiPeriod(null);
    setEmiPeriod(item);
    setDownPaymentAmount(0);
    setEmiInstallment(0);

    let down_payment_amount = 0;
    if (bank.down_payment == 1) {
      if (bank.down_payment_type == 1) {
        setDownPaymentAmount(bank.down_payment_amount);
        down_payment_amount = bank.down_payment_amount;
      } else {
        setDownPaymentAmount(
          (bank.down_payment_amount / 100) * brandProduct.emi_price
        );
        down_payment_amount =
          (bank.down_payment_amount / 100) * brandProduct.emi_price;
      }
    }
    setEmiInstallment(
      (brandProduct.emi_price - down_payment_amount) / item.months
    );
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setErrorMessageFullname("");
    setErrorMessageEmail("");
    setErrorMessagePhone("");
    setErrorMessageAddress("");
    // setErrorMessageLocation("");
    // setErrorMessageDealer("");
    // setSuccessMessage("");

    let formError = false;

    const pattern = /[^a-zA-Z\s.]/g;
    if (fullName == "") {
      setErrorMessageFullname("Please enter fullname");
      formError = true;
    } else if (pattern.test(fullName)) {
      setErrorMessageFullname("Name should not contain special characters.");
      formError = true;
    }

    const emailRegExp = /\S+@\S+\.\S+/;
    if (email == "") {
      setErrorMessageEmail("Please enter email");
      formError = true;
    } else if (!emailRegExp.test(email)) {
      setErrorMessageEmail("Please enter valid email address.");
      formError = true;
    }

    if (phoneNumber == "") {
      setErrorMessagePhone("Please enter mobile number");
      formError = true;
    } else if (phoneNumber.length != 10) {
      setErrorMessagePhone("Please enter 10 digit mobile number.");
      formError = true;
    }

    if (address == "") {
      setErrorMessageAddress("Please enter address");
      formError = true;
    }

    // if (!emiLocation) {
    //   setErrorMessageLocation("Please select location");
    //   formError = true;
    // }

    // if (!emiDealer) {
    //   setErrorMessageDealer("Please select dealer");
    //   formError = true;
    // }

    if (formError == false) {
      let url = new URL(Data.API_BASE_URL + "elex/emi/emi-request");

      setIsloading(true);
      fetch(url, {
        method: "POST",
        headers: Data.API_HEADER,
        body: JSON.stringify({
          applicant_name: fullName,
          email: email,
          address: address,
          contact_number: phoneNumber,
          contact_number2: phoneNumber,
          product_id: brandProduct.id,
          emi_bank_id: bank.id,
          emi_price: brandProduct.emi_price,
          emi_period: emiPeriod.months,
          // dealer_id: emiDealer.id,
          // dealer_name: emiDealer.name,
          // dealer_address: emiDealer.address,
          // dealer_contact: emiDealer.mobile_number,
          // dealer_email: emiDealer.email,
        }),
      })
        .then(api.handleResponseAndError)
        .then((response) => {
          console.log(response);
          if (response.statusCode == 200) {
            Navigate("/emi-success", {
              state: {
                data: response.data,
              },
            });
            setIsloading(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const checkboxHandler = () => {
    setAgree(!agree);
  };

  // const handleOpenProductDetails = () => {
  //   setShowDes(true);
  // };

  function fetchSubCategories() {
    setIsloading(true);
    fetch(Data.API_BASE_URL + "elex/emi/subCategories", {
      method: "GET",
    })
      .then(api.handleResponseAndError)
      .then((response) => {
        setSubCategories(response);
        setIsloading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function fetchSubCategoryTypes(subCategory) {
    setSubCategoryType(null);
    setSubCategoryTypes([]);
    setBrandProduct(null);
    setBrandProducts([]);
    setIsloading(true);
    fetch(Data.API_BASE_URL + "elex/emi/subCategoryTypes/" + subCategory.id, {
      method: "GET",
    })
      .then(api.handleResponseAndError)
      .then((response) => {
        setSubCategoryTypes(response);
        setIsloading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function fetchBrandsViaSubCategory(item) {
    setIsloading(true);
    fetch(Data.API_BASE_URL + "elex/emi/brands-via-sub-category/" + item.id, {
      method: "GET",
    })
      .then(api.handleResponseAndError)
      .then((response) => {
        setBrands(response);
        setIsloading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function fetchBrandsViaSubCategoryType(item) {
    setIsloading(true);
    fetch(Data.API_BASE_URL + "elex/emi/brands-via-category-type/" + item.id, {
      method: "GET",
    })
      .then(api.handleResponseAndError)
      .then((response) => {
        setBrands(response);
        setIsloading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function fetchBrandProductsViaCategoryType(item) {
    setBrandProduct(null);
    setBrandProducts([]);
    setIsloading(true);
    fetch(
      Data.API_BASE_URL +
        "elex/emi/products-via-category-type-n-brand/" +
        subCategoryType.id +
        "/" +
        item.id,
      {
        method: "GET",
      }
    )
      .then(api.handleResponseAndError)
      .then((response) => {
        setBrandProducts(response);
        setIsloading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function fetchBrandProductsViaSubCategory(item) {
    setBrandProduct(null);
    setBrandProducts([]);
    setIsloading(true);
    fetch(
      Data.API_BASE_URL +
        "elex/emi/products-via-sub-category-n-brand/" +
        subCategory.id +
        "/" +
        item.id,
      {
        method: "GET",
      }
    )
      .then(api.handleResponseAndError)
      .then((response) => {
        setBrandProducts(response);
        setIsloading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // function fetchEmiLocation() {
  //   setEmiLocations([]);
  //   fetch(Data.API_BASE_URL_CRM + "exchange/districts?brand_id=" + brand.id, {
  //     method: "GET",
  //   })
  //     .then(api.handleResponseAndError)
  //     .then((response) => {
  //       setEmiLocations(response);
  //       setIsloading(false);
  //     })
  //     .catch((error) => {});
  // }

  // const handleEmiDealer = (id) => {
  //   const dealer = emiDealers.find((dealer) => dealer.id == id);
  //   if (dealer) {
  //     setEmiDealer(dealer);
  //   }
  // };

  // function fetchEmiDealers(districtId) {
  //   fetch(Data.API_BASE_URL_CRM + "exchange/outlets/" + districtId, {
  //     method: "GET",
  //   })
  //     .then(api.handleResponseAndError)
  //     .then((response) => {
  //       setEmiDealers(response);
  //       setIsloading(false);
  //     })
  //     .catch((error) => {});
  // }

  function fetchBankLogo() {
    fetch(Data.API_BASE_URL + "elex/emi/emi-banks", {
      method: "GET",
    })
      .then(api.handleResponseAndError)
      .then((response) => {
        setBanks(response);
        setIsloading(false);
      })
      .catch((error) => {});
  }

  useEffect(() => {
    fetchSubCategories();
    fetchBankLogo();
    // fetchEmiLocation();
  }, []);
  return (
    <div>
      {isLoading ? (
        <div
          classNameName="container"
          style={{ textAlign: "center", padding: "100px 0px" }}
        >
          <ClipLoader
            css={{ margin: "0 auto" }}
            size={150}
            //size={"150px"} this also works
            color={"#123abc"}
            loading={isLoading}
          />
        </div>
      ) : (
        <>
          {subCategories.length > 0 ? (
            <section
              className="service-section service-page paddingformobile"
              id="choose-new-product"
            >
              <div class="div-title elexheads"> Choose the product</div>
              <div className="thm-container">
                <div className="row">
                  {subCategories.map(function (item, index) {
                    return (
                      <div
                        key={index}
                        className="col-md-3 col-sm-6 col-6 mobilegrid"
                      >
                        <div className="single-service-wrapper bg-1">
                          <div className="single-service-content text-center">
                            <img src={item.icon} width="100" alt={item.name} />
                            <h3
                              className="elexopttitleswithimg"
                              style={{
                                marginBottom: "0",
                              }}
                            >
                              {item.name}
                            </h3>
                            <HashLink
                              to={
                                item.type
                                  ? "#choose-new-product-type"
                                  : "#choose-brand"
                              }
                            >
                              <a
                                type="button"
                                className={
                                  subCategory && subCategory.id === item.id
                                    ? "read-more selected"
                                    : "read-more "
                                }
                                onClick={() => {
                                  setSubCategory(item);
                                  setBrand(null);
                                  setSubCategoryType(null);
                                  setSubCategoryTypes([]);
                                  setBrandProducts([]);
                                  setBrands([]);
                                  if (item.type) {
                                    fetchSubCategoryTypes(item);
                                  } else {
                                    fetchBrandsViaSubCategory(item);
                                  }
                                }}
                              >
                                {/* <i className="fa fa-arrow-right"></i> */}
                              </a>
                            </HashLink>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </section>
          ) : (
            ""
          )}

          {subCategoryTypes.length > 0 ? (
            <section
              className="service-section service-page service-section-sm paddingformobile"
              id="choose-new-product-type"
            >
              <div class="div-title elexheads">Choose Product Type</div>
              <div className="thm-container">
                <div className="row">
                  {subCategoryTypes.map(function (item, index) {
                    return (
                      <div
                        key={index}
                        className="col-md-3 col-sm-6 col-6 mobilegrid"
                      >
                        <div className="single-service-wrapper bg-1">
                          <div className="single-service-content text-center">
                            <h5
                              className="elexopttitles"
                              style={{
                                marginBottom: "0",
                              }}
                            >
                              {item.name}
                            </h5>
                            <HashLink to="#choose-brand">
                              <a
                                type="button"
                                className={
                                  subCategoryType &&
                                  subCategoryType.id === item.id
                                    ? "read-more selected"
                                    : "read-more "
                                }
                                onClick={() => {
                                  setSubCategoryType(item);
                                  fetchBrandsViaSubCategoryType(item);
                                  setBrand(null);
                                  setBrands([]);
                                  setBrandProducts([]);
                                  setBrandProduct(null);
                                }}
                              >
                                {/* <i className="fa fa-arrow-right"></i> */}
                              </a>
                            </HashLink>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </section>
          ) : (
            ""
          )}

          {brands.length > 0 ? (
            <section
              className="service-section service-page paddingformobile"
              id="choose-brand"
              style={{ paddingTop: "0" }}
            >
              <div class="div-title elexheads"> Choose the product brand</div>
              <div className="thm-container">
                <div className="row">
                  {brands.map(function (item, index) {
                    return (
                      <div
                        key={index}
                        className="col-md-3 col-sm-6 col-6 mobilegrid"
                      >
                        <div className="single-service-wrapper bg-1">
                          <div className="single-service-content text-center">
                            <img src={item.image} width="100" alt={item.name} />
                            <h3
                              style={{
                                marginBottom: "0",
                              }}
                            >
                              {/* {item.name} */}
                            </h3>
                            <HashLink to="#choose-new-model">
                              <a
                                type="button"
                                className={
                                  brand && brand.id === item.id
                                    ? "read-more selected"
                                    : "read-more "
                                }
                                onClick={() => {
                                  setBrand(item);
                                  if (subCategory.type) {
                                    fetchBrandProductsViaCategoryType(item);
                                  } else {
                                    fetchBrandProductsViaSubCategory(item);
                                  }
                                }}
                              >
                                {/* <i className="fa fa-arrow-right"></i> */}
                              </a>
                            </HashLink>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </section>
          ) : (
            ""
          )}

          {brandProducts.length > 0 ? (
            // test

            <section
              className="service-section service-page"
              id="choose-new-model"
            >
              <div class="div-title elexheads">Choose Model</div>
              <div className="thm-container">
                <div className="row">
                  {brandProducts.map(function (item, index) {
                    return (
                      <div
                        // key={index}
                        className="col-md-3 col-sm-6 col-6 mobilegrid product_name"
                      >
                        <div className="col-sm-12 single-service-wrapper bg-1">
                          <div className="single-service-content text-center">
                            <span className="save-float">
                              Save{" "}
                              {Math.round(
                                ((item.price - item.emi_price) /
                                  item.emi_price) *
                                  100
                              )}
                              %
                            </span>
                            <div style={{ height: "100px" }}>
                              <img
                                src={item.featured_image}
                                width="100"
                                alt={item.name}
                              />
                            </div>
                            <h5
                              className="elexopttitleswithimg"
                              style={{ marginTop: "10px" }}
                            >
                              {item.name}{" "}
                              <span
                                className="view-details"
                                style={{ color: "#1193d4" }}
                                onClick={() => {
                                  openModal();
                                  setBrandProduct(item);
                                }}
                              >
                                <FaEye />
                              </span>
                            </h5>
                            <h6
                              className="elexnewprodmodel"
                              style={{
                                marginBottom: "0",
                              }}
                            >
                              {item.model_number}
                            </h6>
                            <div style={{ padding: "10px 25px 0 25px" }}>
                              <h6 className="elexnewprodprice">
                                MRP{" "}
                                <span style={{ color: "#174cbb" }}>
                                  NPR {parseFloat(item.price).toFixed(2)}
                                </span>
                              </h6>
                              <h6 className="elexnewprodprice">
                                EMI Price{" "}
                                <span style={{ color: "#174cbb" }}>
                                  NPR {parseFloat(item.emi_price).toFixed(2)}
                                </span>
                              </h6>
                            </div>

                            {brandProduct ? (
                              <Modal
                                isOpen={modalIsOpen}
                                onRequestClose={closeModal}
                                style={customStyles}
                                contentLabel="Example Modal"
                              >
                                <div className="row modalrow">
                                  <div className="col-lg-6">
                                    <FaTimes
                                      onClick={closeModal}
                                      style={{ cursor: "pointer" }}
                                    />
                                    <div style={{ width: "60%" }}>
                                      <img src={brandProduct.featured_image} />
                                    </div>
                                  </div>
                                  <div
                                    className="col-lg-6"
                                    style={{
                                      background: "#9e9e9e14",
                                      padding: "20px",
                                      borderRadius: "10px",
                                    }}
                                  >
                                    <h2 className="modalprodname ">
                                      {brandProduct.name}
                                    </h2>
                                    <hr />
                                    <h5 className="modalprodmodel">
                                      Model:{" "}
                                      <strong style={{ color: "#246bfd" }}>
                                        {brandProduct.model_number}
                                      </strong>
                                    </h5>
                                    <h5 className="modalprodmodel">
                                      Brand:{" "}
                                      <strong style={{ color: "#246bfd" }}>
                                        {brandProduct.brand_name}
                                      </strong>
                                    </h5>
                                    <h5 className="modalprodmodel">
                                      MRP:{" "}
                                      <strong style={{ color: "#246bfd" }}>
                                        NPR {brandProduct.price}
                                      </strong>
                                    </h5>
                                    <h5 className="modalprodmodel">
                                      EMI Price:{" "}
                                      <strong style={{ color: "#246bfd" }}>
                                        NPR {brandProduct.emi_price}
                                      </strong>
                                    </h5>
                                    <h6
                                      style={{ fontWeight: "bold" }}
                                      className="modalprodoverview"
                                    >
                                      <span
                                        style={{ textDecoration: "underline" }}
                                      >
                                        Quick Overview
                                      </span>

                                      <p
                                        className="quick_overview modalproddesc"
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            brandProduct.short_description,
                                        }}
                                        style={{
                                          fontSize: "14px",
                                          lineHeight: "20px",
                                        }}
                                      />
                                    </h6>
                                  </div>

                                  <Tab.Container
                                    defaultActiveKey="first"
                                    activeKey={currentTab}
                                    onSelect={(key) => setCurrentTab(key)}
                                    className="nav tabs"
                                  >
                                    <Col className="">
                                      <Nav
                                        variant="pills"
                                        className="tab prd-desc"
                                        align="left"
                                      >
                                        <Nav.Item>
                                          <Nav.Link
                                            eventKey="first"
                                            className="modaltabbs"
                                          >
                                            Description
                                          </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                          <Nav.Link
                                            eventKey="second"
                                            className="modaltabbs"
                                          >
                                            Product Specs
                                          </Nav.Link>
                                        </Nav.Item>
                                      </Nav>
                                    </Col>
                                    <Tab.Content>
                                      <Tab.Pane
                                        eventKey="first"
                                        style={{
                                          marginTop: "10px",
                                          background: "#fff",
                                          animationDuration: "0.5s",
                                        }}
                                      >
                                        <a onClick={handleSelect}>
                                          <div
                                            className="modalprodlongdescription"
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                brandProduct.long_description,
                                            }}
                                          ></div>
                                        </a>
                                      </Tab.Pane>
                                      <Tab.Pane
                                        eventKey="second"
                                        style={{
                                          marginTop: "10px",
                                          background: "#fff",
                                          animationDuration: "0.5s",
                                        }}
                                      >
                                        <p>
                                          {brandProduct.product_specifications ? (
                                            <section className="specs-wrp">
                                              <div className="detailspecbox">
                                                <table
                                                  width="100%"
                                                  border="0"
                                                  cellSpacing="2"
                                                  cellPadding="0"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td>
                                                        <table
                                                          width="100%"
                                                          border="0"
                                                          cellSpacing="0"
                                                          cellPadding="0"
                                                          className="product-spec"
                                                          itemProp="description"
                                                        >
                                                          <tbody>
                                                            {brandProduct.product_specifications.map(
                                                              function (
                                                                product_specifications,
                                                                index
                                                              ) {
                                                                return (
                                                                  <tr
                                                                    key={index}
                                                                  >
                                                                    <td className="specs-heading modalprodlongdescription">
                                                                      {
                                                                        product_specifications
                                                                          .product_feature
                                                                          .name
                                                                      }
                                                                    </td>
                                                                    <td
                                                                      itemProp="brand"
                                                                      className="modalprodlongdescription"
                                                                      style={{
                                                                        color:
                                                                          "#246bfd",
                                                                      }}
                                                                    >
                                                                      {
                                                                        product_specifications.feature_value
                                                                      }
                                                                    </td>
                                                                  </tr>
                                                                );
                                                              }
                                                            )}
                                                          </tbody>
                                                        </table>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </section>
                                          ) : (
                                            ""
                                          )}
                                        </p>
                                      </Tab.Pane>
                                    </Tab.Content>
                                  </Tab.Container>
                                </div>
                              </Modal>
                            ) : (
                              ""
                            )}

                            <HashLink to="#submission-form">
                              <a
                                type="button"
                                className={
                                  brandProduct && brandProduct.id === item.id
                                    ? "read-more selected"
                                    : "read-more "
                                }
                                onClick={() => {
                                  setBrandProduct(item);
                                  setSelectedImage(null);
                                  setBank(null);
                                  setEmiPeriod(null);
                                }}
                              >
                                {/* <i className="fa fa-arrow-right"></i> */}
                              </a>
                            </HashLink>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </section>
          ) : (
            ""
          )}

          {brandProduct ? (
            <section className="login-register" id="submission-form">
              <div className="thm-container ">
                <form onSubmit={handleSubmit}>
                  <div className="row form-row formdecor">
                    <div className="title">
                      <h2>Confirm Your EMI Request</h2>
                    </div>
                    {/* {brandProducts.map(function (item, index) {
                      return ( */}
                    <div className="confirm-emi">
                      <div className="col-md-6">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            background: "#fff",
                            margin: "0 15px",
                            borderRadius: "5px",
                            padding: "15px",
                          }}
                        >
                          <div style={{ width: "60%" }}>
                            <img
                              src={brandProduct.featured_image}
                              style={{ width: "100%" }}
                            />
                          </div>
                        </div>
                      </div>

                      <div
                        className="col-md-6"
                        style={{
                          padding: "20px",
                          borderRadius: "10px",
                        }}
                      >
                        <h2
                          className="modalprodname"
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <span>{brandProduct.name}</span>

                          <span
                            className="view-details"
                            style={{ color: "#1193d4", marginLeft: "10px" }}
                            onClick={() => {
                              openModal();
                              setBrandProduct(brandProduct);
                            }}
                          >
                            <FaEye />
                          </span>
                        </h2>
                        <hr />
                        <h5 className="modalprodmodel">
                          Model:{" "}
                          <strong style={{ color: "#246bfd" }}>
                            {brandProduct.model_number}
                          </strong>
                        </h5>
                        <h5 className="modalprodmodel">
                          Brand:{" "}
                          <strong style={{ color: "#246bfd" }}>
                            {brandProduct.brand_name}
                          </strong>
                        </h5>
                        <h5 className="modalprodmodel">
                          MRP:{" "}
                          <strong style={{ color: "#246bfd" }}>
                            NPR {parseFloat(brandProduct.price).toFixed(2)}
                          </strong>
                        </h5>
                        <h5 className="modalprodmodel">
                          EMI Price:{" "}
                          <strong style={{ color: "#246bfd" }}>
                            NPR {parseFloat(brandProduct.emi_price).toFixed(2)}
                          </strong>
                        </h5>
                        <span className="save-float-confirm">
                          Save{" "}
                          {Math.round(
                            ((brandProduct.price - brandProduct.emi_price) /
                              brandProduct.emi_price) *
                              100
                          )}
                          %
                        </span>
                        <h6
                          style={{ fontWeight: "bold" }}
                          className="modalprodoverview"
                        >
                          <span style={{ textDecoration: "underline" }}>
                            Quick Overview
                          </span>

                          <p
                            className="quick_overview modalproddesc"
                            dangerouslySetInnerHTML={{
                              __html: brandProduct.short_description,
                            }}
                            style={{
                              fontSize: "14px",
                              lineHeight: "20px",
                            }}
                          />
                        </h6>
                      </div>

                      {brandProduct ? (
                        <Modal
                          isOpen={modalIsOpen}
                          onRequestClose={closeModal}
                          style={customStyles}
                          contentLabel="Example Modal"
                        >
                          <div className="row modalrow">
                            <div className="col-lg-6">
                              <FaTimes
                                onClick={closeModal}
                                style={{ cursor: "pointer" }}
                              />
                              <div style={{ width: "60%" }}>
                                <img src={brandProduct.featured_image} />
                              </div>
                            </div>
                            <div
                              className="col-lg-6"
                              style={{
                                background: "#9e9e9e14",
                                padding: "20px",
                                borderRadius: "10px",
                              }}
                            >
                              <h2 className="modalprodname ">
                                {brandProduct.name}
                              </h2>
                              <hr />
                              <h5 className="modalprodmodel">
                                Model:{" "}
                                <strong style={{ color: "#246bfd" }}>
                                  {brandProduct.model_number}
                                </strong>
                              </h5>
                              <h5 className="modalprodmodel">
                                Brand:{" "}
                                <strong style={{ color: "#246bfd" }}>
                                  {brandProduct.brand_name}
                                </strong>
                              </h5>
                              <h5 className="modalprodmodel">
                                MRP:{" "}
                                <strong style={{ color: "#246bfd" }}>
                                  NPR {brandProduct.price}
                                </strong>
                              </h5>
                              <h5 className="modalprodmodel">
                                EMI Price:{" "}
                                <strong style={{ color: "#246bfd" }}>
                                  NPR {brandProduct.emi_price}
                                </strong>
                              </h5>
                              <span className="save-float-confirm">
                                Save{" "}
                                {Math.round(
                                  ((brandProduct.price -
                                    brandProduct.emi_price) /
                                    brandProduct.emi_price) *
                                    100
                                )}
                                %
                              </span>
                              <h6
                                style={{ fontWeight: "bold" }}
                                className="modalprodoverview"
                              >
                                <span
                                  style={{
                                    textDecoration: "underline",
                                  }}
                                >
                                  Quick Overview
                                </span>

                                <p
                                  className="quick_overview modalproddesc"
                                  dangerouslySetInnerHTML={{
                                    __html: brandProduct.short_description,
                                  }}
                                  style={{
                                    fontSize: "14px",
                                    lineHeight: "20px",
                                  }}
                                />
                              </h6>
                            </div>

                            <Tab.Container
                              defaultActiveKey="first"
                              activeKey={currentTab}
                              onSelect={(key) => setCurrentTab(key)}
                              className="nav tabs"
                            >
                              <Col className="">
                                <Nav
                                  variant="pills"
                                  className="tab prd-desc"
                                  align="left"
                                >
                                  <Nav.Item>
                                    <Nav.Link
                                      eventKey="first"
                                      className="modaltabbs"
                                    >
                                      Description
                                    </Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item>
                                    <Nav.Link
                                      eventKey="second"
                                      className="modaltabbs"
                                    >
                                      Product Specs
                                    </Nav.Link>
                                  </Nav.Item>
                                </Nav>
                              </Col>
                              <Tab.Content>
                                <Tab.Pane
                                  eventKey="first"
                                  style={{
                                    marginTop: "10px",
                                    background: "#fff",
                                    animationDuration: "0.5s",
                                  }}
                                >
                                  <a onClick={handleSelect}>
                                    <div
                                      className="modalprodlongdescription"
                                      dangerouslySetInnerHTML={{
                                        __html: brandProduct.long_description,
                                      }}
                                    ></div>
                                  </a>
                                </Tab.Pane>
                                <Tab.Pane
                                  eventKey="second"
                                  style={{
                                    marginTop: "10px",
                                    background: "#fff",
                                    animationDuration: "0.5s",
                                  }}
                                >
                                  <p>
                                    {brandProduct.product_specifications ? (
                                      <section className="specs-wrp">
                                        <div className="detailspecbox">
                                          <table
                                            width="100%"
                                            border="0"
                                            cellSpacing="2"
                                            cellPadding="0"
                                          >
                                            <tbody>
                                              <tr>
                                                <td>
                                                  <table
                                                    width="100%"
                                                    border="0"
                                                    cellSpacing="0"
                                                    cellPadding="0"
                                                    className="product-spec"
                                                    itemProp="description"
                                                  >
                                                    <tbody>
                                                      {brandProduct.product_specifications.map(
                                                        function (
                                                          product_specifications,
                                                          index
                                                        ) {
                                                          return (
                                                            <tr key={index}>
                                                              <td className="specs-heading modalprodlongdescription">
                                                                {
                                                                  product_specifications
                                                                    .product_feature
                                                                    .name
                                                                }
                                                              </td>
                                                              <td
                                                                itemProp="brand"
                                                                className="modalprodlongdescription"
                                                                style={{
                                                                  color:
                                                                    "#246bfd",
                                                                }}
                                                              >
                                                                {
                                                                  product_specifications.feature_value
                                                                }
                                                              </td>
                                                            </tr>
                                                          );
                                                        }
                                                      )}
                                                    </tbody>
                                                  </table>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </section>
                                    ) : (
                                      ""
                                    )}
                                  </p>
                                </Tab.Pane>
                              </Tab.Content>
                            </Tab.Container>
                          </div>
                        </Modal>
                      ) : (
                        ""
                      )}
                    </div>
                    {/* );
                    })} */}

                    <div className="bank-n-emis" id="select-bank">
                      <div className="col-md-12 boxa">
                        <div className="sub-boxa">
                          <div className="col-md-12 emi-heads">
                            Select Finance Partner
                          </div>
                        </div>
                        <div className="row">
                          {/* mapImg */}

                          {banks.map((item, index) => (
                            <div
                              key={index}
                              className="col-md-3 bankings mob-bankings"
                            >
                              {selectedImage && selectedImage === item.id ? (
                                <i
                                  class="fa fa-check bank-ticks"
                                  aria-hidden="true"
                                ></i>
                              ) : (
                                ""
                              )}

                              <HashLink to="#select-bank">
                                <img
                                  alt=""
                                  src={item.logo}
                                  onClick={() => handleBankClick(item)}
                                />
                              </HashLink>
                            </div>
                          ))}
                        </div>

                        {bank && bank.emi_periods.length > 0 ? (
                          <>
                            <div id="select-period">
                              <div className="sub-boxa1">
                                <div className="col-md-12 emi-heads">
                                  Select EMI Period
                                </div>
                              </div>
                              <div className="row emi-period-wrapper">
                                {bank.emi_periods.map((item, index) => (
                                  <div className="col-md-3 emi-period">
                                    <span
                                      className={
                                        emiPeriod &&
                                        emiPeriod.name === item.name
                                          ? "selected"
                                          : ""
                                      }
                                      key={index}
                                      onClick={() => handlePeriodClick(item)}
                                    >
                                      {item.name}
                                    </span>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    {emiPeriod ? (
                      <>
                        <div
                          className=""
                          style={{ backgroundColor: "#005ba4" }}
                        >
                          <div className="col-md-12 emi-heads">EMI Details</div>
                        </div>
                        <div className="col-md-12 boxa">
                          <div style={{ padding: "10px 0 15px 0" }}>
                            <div className="row emiDet">
                              <div className="emi-result-divs emi-desc col-md-3">
                                <img
                                  alt=""
                                  src={bank.logo}
                                  style={{ width: "150px" }}
                                />
                              </div>

                              <div className="emi-result-divs emi-desc col-md-2">
                                <font color="#f01b25">Down Payment:</font>{" "}
                                <br />
                                <span className="elexnewprodpriceemi">
                                  NPR {parseFloat(downPaymentAmount).toFixed(2)}
                                </span>
                              </div>
                              <div className="emi-result-divs emi-desc col-md-2">
                                <font color="#005ba4">EMI Price:</font> <br />
                                <span className="elexnewprodpriceemi">
                                  NPR{" "}
                                  {parseFloat(brandProduct.emi_price).toFixed(
                                    2
                                  )}
                                </span>
                              </div>

                              <div className="emi-result-divs emi-desc col-md-2">
                                <font color="#005ba4">EMI Period:</font> <br />
                                <span className="elexnewprodpriceemi">
                                  {emiPeriod.name}
                                </span>
                              </div>

                              <div className="emi-result-divs emi-desc col-md-2">
                                <font color="#005ba4">
                                  Monthly Installment:
                                </font>{" "}
                                <br />
                                <span className="elexnewprodpriceemi">
                                  NPR {emiInstallment.toFixed(2)}
                                </span>
                              </div>
                            </div>

                            <div className="row ">
                              <div className="col-md-3">
                                <input
                                  type="text"
                                  placeholder="Full Name"
                                  name="full_name"
                                  style={{
                                    paddingLeft: "10px",
                                    height: "40px",
                                  }}
                                  onChange={(e) => setFullName(e.target.value)}
                                  {...bindFullName}
                                />
                                {errorMessageFullname ? (
                                  <>
                                    <p style={{ color: "red" }}>
                                      {errorMessageFullname}
                                    </p>
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="col-md-3">
                                <input
                                  type="number"
                                  // maxLength="10"
                                  pattern="[1-9]{1}[0-9]{9}"
                                  placeholder="Mobile Number"
                                  name="mobile_no"
                                  style={{
                                    paddingLeft: "10px",
                                    height: "40px",
                                  }}
                                  onChange={(e) => {
                                    setPhoneNumber(e.target.value);
                                  }}
                                  {...phoneNumber}
                                />
                                {errorMessagePhone ? (
                                  <>
                                    <p style={{ color: "red" }}>
                                      {errorMessagePhone}
                                    </p>
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="col-md-3">
                                <input
                                  type="text"
                                  placeholder="Email Id"
                                  name="email"
                                  style={{
                                    paddingLeft: "10px",
                                    height: "40px",
                                  }}
                                  onChange={(e) => setEmail(e.target.value)}
                                  {...email}
                                />
                                {errorMessageEmail ? (
                                  <>
                                    <p style={{ color: "red" }}>
                                      {errorMessageEmail}
                                    </p>
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="col-md-3">
                                <input
                                  type="text"
                                  placeholder="Address"
                                  name="address"
                                  style={{
                                    paddingLeft: "10px",
                                    height: "40px",
                                  }}
                                  onChange={(e) => setAddress(e.target.value)}
                                  {...address}
                                />
                                {errorMessageAddress ? (
                                  <>
                                    <p style={{ color: "red" }}>
                                      {errorMessageAddress}
                                    </p>
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                              {/* <div className="col-lg-3">
                                <select
                                  className="select_edit"
                                  style={{ border: "solid thin #289dd838" }}
                                  onChange={(e) => {
                                    setEmiLocation(e.target.value);
                                    setEmiDealers([]);
                                    setEmiDealer(null);
                                    fetchEmiDealers(e.target.value);
                                  }}
                                >
                                  <option value="">Select Location</option>
                                  {emiLocations.map((item, index) => (
                                    <option key={index} value={item.id}>
                                      {item.name}
                                    </option>
                                  ))}
                                </select>
                                {errorMessageLocation ? (
                                  <>
                                    <p style={{ color: "red" }}>
                                      {errorMessageLocation}
                                    </p>
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="col-lg-3">
                                <select
                                  className="select_edit"
                                  style={{ border: "solid thin #289dd838" }}
                                  onChange={(e) => {
                                    handleEmiDealer(e.target.value);
                                  }}
                                >
                                  <option value="">Select Dealer</option>
                                  {emiDealers.map((item, index) => (
                                    <option value={item.id} key={index}>
                                      {item.name}
                                    </option>
                                  ))}
                                </select>
                                {errorMessageDealer ? (
                                  <>
                                    <p style={{ color: "red" }}>
                                      {errorMessageDealer}
                                    </p>
                                  </>
                                ) : (
                                  ""
                                )}
                              </div> */}
                              <div className="col-md-3"></div>
                              <div className="col-md-3"></div>
                              <div className="col-md-3">
                                <div class="form-check">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    id="i_agree"
                                    name="i_agree"
                                    onChange={checkboxHandler}
                                  />
                                  <label
                                    id="i-agree-label"
                                    class="form-check-label"
                                    for="IAgree"
                                    style={{ fontSize: "12px" }}
                                  >
                                    I agree to the{" "}
                                    <a
                                      href="terms-and-conditions"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      style={{ color: "#289dd8" }}
                                    >
                                      Terms and Conditions
                                    </a>{" "}
                                    and{" "}
                                    <a
                                      href="privacy-policy"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      style={{ color: "#289dd8" }}
                                    >
                                      Privacy Policy
                                    </a>
                                    .
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <button
                                  type="submit"
                                  className="pull-right"
                                  style={{ width: "100%", height: "90%" }}
                                  disabled={!agree}
                                >
                                  Request EMI
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </form>
              </div>
            </section>
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
};

export default Emi;
