import React, { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import "./Index.css";
import Data from "../utils/data";
import api from "../utils/api";
import { useInput } from "../hooks/inputHook";
import { HashLink } from "react-router-hash-link";
import { FaArrowCircleLeft } from "react-icons/fa";
import { Navigate, useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { FaTimes, FaEye } from "react-icons/fa";
import { Tab, Col, Nav, Form } from "react-bootstrap";
import { isDisabled } from "@testing-library/user-event/dist/utils";

const Index = () => {
  const [isLoading, setIsloading] = useState(true);
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState(null);
  const [productType, setProductType] = useState(null);
  const [workingCondition, setWorkingCondition] = useState([]);
  const [next, setNext] = useState(false);
  const [productTypes, setProductTypes] = useState([]);

  const [brands, setBrands] = useState([]);
  const [brand, setBrand] = useState(null);
  const [subCategories, setSubCategories] = useState([]);
  const [subCategory, setSubCategory] = useState(null);
  console.log(subCategory);
  const [subCategoryTypes, setSubCategoryTypes] = useState([]);
  const [subCategoryType, setSubCategoryType] = useState(null);
  const [brandProducts, setBrandProducts] = useState([]);
  const [brandProduct, setBrandProduct] = useState(null);
  const [exchangePrice, setExchangePrice] = useState(null);
  const [exchangeOfferPrice, setExchangeOfferPrice] = useState(null);
  const [currentTab, setCurrentTab] = useState("first");
  const [disabled, setIsDisabled] = useState(false);

  const handleSelect = (eventKey) => {
    setCurrentTab("second");
  };

  const {
    value: fullName,
    bind: bindFullName,
    setValue: setFullName,
  } = useInput("");
  const {
    value: address,
    bind: bindAddress,
    setValue: setAddress,
  } = useInput("");
  const { value: phone, bind: bindPhone, setValue: setPhone } = useInput("");
  const { value: email, bind: bindEmail, setValue: setEmail } = useInput("");
  const {
    value: altPhone,
    bind: bindAltPhone,
    setValue: setAltPhone,
  } = useInput("");

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessageFullname, setErrorMessageFullname] = useState("");
  const [errorMessageEmail, setErrorMessageEmail] = useState("");
  const [errorMessagePhone, setErrorMessagePhone] = useState("");
  const [errorMessageAddress, setErrorMessageAddress] = useState("");

  const [workingConditionAnswerd, setWorkingConditionAnswerd] = useState(false);

  const [agree, setAgree] = useState(false);

  const [isThankYouMessageVisible, setIsThankYouMessageVisible] =
    useState(false);

  const [modalIsOpen, setIsOpen] = useState(false);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      zIndex: "99999",
      width: "80%",
      height: "80%",
    },
    overlay: { zIndex: 1000 },
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const Navigate = useNavigate();

  function fetchProducts() {
    fetch(Data.API_BASE_URL + "exchange/products", {
      method: "GET",
    })
      .then(api.handleResponseAndError)
      .then((response) => {
        setProducts(response);
        setIsloading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function fetchProductTypes(product) {
    setProductType(null);
    setProductTypes([]);
    setIsloading(true);
    fetch(Data.API_BASE_URL + "exchange/productTypes/" + product.id, {
      method: "GET",
    })
      .then(api.handleResponseAndError)
      .then((response) => {
        setProduct(product);
        setProductTypes(response);
        setWorkingCondition(null);

        // const element = document.getElementById('newsletter-validate-detail');
        // if (element) {
        //   element.scrollIntoView();
        // }
        setIsloading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function fetchWorkingCondition(productType) {
    setWorkingCondition([]);
    setIsloading(true);
    fetch(Data.API_BASE_URL + "exchange/workingConditions/" + productType.id, {
      method: "GET",
    })
      .then(api.handleResponseAndError)
      .then((response) => {
        console.log(response);
        setWorkingCondition(response);

        setIsloading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function fetchBrands() {
    setIsloading(true);
    fetch(Data.API_BASE_URL + "exchange/brands", {
      method: "GET",
    })
      .then(api.handleResponseAndError)
      .then((response) => {
        setBrands(response);
        setIsloading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function fetchBrandsViaSubCategoryType(item) {
    setIsloading(true);
    fetch(Data.API_BASE_URL + "exchange/brands-via-category-type/" + item.id, {
      method: "GET",
    })
      .then(api.handleResponseAndError)
      .then((response) => {
        setBrands(response);
        setIsloading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function fetchBrandsViaSubCategory(item) {
    setIsloading(true);
    fetch(Data.API_BASE_URL + "exchange/brands-via-sub-category/" + item.id, {
      method: "GET",
    })
      .then(api.handleResponseAndError)
      .then((response) => {
        setBrands(response);
        setIsloading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function fetchSubCategories() {
    setSubCategory(null);
    setSubCategories([]);
    setSubCategoryType(null);
    setSubCategoryTypes([]);
    setBrand(null);
    setBrands([]);
    setBrandProducts([]);
    setIsloading(true);
    fetch(Data.API_BASE_URL + "exchange/subCategories", {
      method: "GET",
    })
      .then(api.handleResponseAndError)
      .then((response) => {
        setSubCategories(response);
        setIsloading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function fetchSubCategoryTypes(subCategory) {
    setSubCategoryType(null);
    setSubCategoryTypes([]);
    setBrandProduct(null);
    setBrandProducts([]);
    setIsloading(true);
    fetch(Data.API_BASE_URL + "exchange/subCategoryTypes/" + subCategory.id, {
      method: "GET",
    })
      .then(api.handleResponseAndError)
      .then((response) => {
        setSubCategoryTypes(response);
        setIsloading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function updateWorkingCondition(questionIndex, answerIndex, answer) {
    workingCondition[questionIndex].user_answer_key = answerIndex;
    workingCondition[questionIndex].user_answer = answer.answer;
    workingCondition[questionIndex].user_answer_id = answer.id;
    workingCondition[questionIndex].user_answer_price = answer.price;
    workingCondition[questionIndex].user_offer_answer_price =
      answer.offer_price;
    setWorkingCondition([...workingCondition]);
    if (
      workingCondition[questionIndex].answers[answerIndex]
        .allow_other_question == "No"
    ) {
      for (let i = questionIndex + 1; i < workingCondition.length; i++) {
        workingCondition[i].user_answer_key = null;
        workingCondition[i].user_answer = null;
        workingCondition[i].user_answer_id = null;
        workingCondition[i].user_answer_price = null;
        workingCondition[i].user_offer_answer_price = null;
      }
      setWorkingConditionAnswerd(true);
    } else {
      var Answerd = true;
      for (let i = 0; i < workingCondition.length; i++) {
        if (workingCondition[i].user_answer == null) {
          Answerd = false;
          break;
        }
      }
      if (Answerd == true) {
        setWorkingConditionAnswerd(true);
      } else {
        setWorkingConditionAnswerd(false);
      }
    }
  }

  function fetchBrandProductsViaCategoryType(item) {
    setBrandProduct(null);
    setBrandProducts([]);
    setIsloading(true);
    fetch(
      Data.API_BASE_URL +
        "exchange/products-via-category-type-n-brand/" +
        subCategoryType.id +
        "/" +
        item.id,
      {
        method: "GET",
      }
    )
      .then(api.handleResponseAndError)
      .then((response) => {
        setBrandProducts(response);
        setIsloading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function fetchBrandProductsViaSubCategory(item) {
    setBrandProduct(null);
    setBrandProducts([]);
    setIsloading(true);
    fetch(
      Data.API_BASE_URL +
        "exchange/products-via-sub-category-n-brand/" +
        subCategory.id +
        "/" +
        item.id,
      {
        method: "GET",
      }
    )
      .then(api.handleResponseAndError)
      .then((response) => {
        setBrandProducts(response);
        setIsloading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleSubmit = (evt) => {
    evt.preventDefault();

    setErrorMessageFullname("");
    setErrorMessageEmail("");
    setErrorMessagePhone("");
    setErrorMessageAddress("");
    setSuccessMessage("");

    let formError = false;

    const pattern = /[^a-zA-Z\s.]/g;
    if (fullName == "") {
      setErrorMessageFullname("Please enter fullname");
      formError = true;
    } else if (pattern.test(fullName)) {
      setErrorMessageFullname("Name should not contain special characters.");
      formError = true;
    }

    const emailRegExp = /\S+@\S+\.\S+/;
    if (email == "") {
      setErrorMessageEmail("Please enter email");
      formError = true;
    } else if (!emailRegExp.test(email)) {
      setErrorMessageEmail("Please enter valid email address.");
      formError = true;
    }

    if (phone == "") {
      setErrorMessagePhone("Please enter mobile number");
      formError = true;
    } else if (phone.length != 10) {
      setErrorMessagePhone("Please enter valid mobile number.");
      formError = true;
    }

    // if(altPhone.length !== 10){
    //   setErrorMessagePhone("Please enter valid mobile number.");
    //   formError = true
    // }

    if (address == "") {
      setErrorMessageAddress("Please enter address");
      formError = true;
    }

    if (formError == false) {
      let url = new URL(Data.API_BASE_URL + "exchange/post-lead");

      setIsloading(true);
      fetch(url, {
        method: "POST",
        headers: Data.API_HEADER,
        body: JSON.stringify({
          fullName: fullName,
          email: email,
          address: address,
          phone: phone,
          altPhone: altPhone,
          condition: workingCondition,
          productTypeId: productType.id,
          brandProductId: brandProduct.id,
        }),
      })
        .then(api.handleResponseAndError)
        .then((response) => {
          if (response.statusCode == 200) {
            // window.location = response.url;
            setFullName("");
            setAddress("");
            setEmail("");
            setPhone("");
            setAltPhone("");
            setSuccessMessage(response.message);

            Navigate("/request-successful", {
              state: {
                code: response.code,
                expiry_text: response.expiry_text,
                data: response.data,
              },
            });
            setIsloading(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const checkboxHandler = () => {
    setAgree(!agree);
  };

  useEffect(() => {
    fetchProducts();
  }, []);
  return (
    <React.Fragment>
      {isLoading ? (
        <div
          classNameName="container"
          style={{ textAlign: "center", padding: "100px 0px" }}
        >
          <ClipLoader
            css={{ margin: "0 auto" }}
            size={150}
            //size={"150px"} this also works
            color={"#123abc"}
            loading={isLoading}
          />
        </div>
      ) : (
        <>
          <div className="exchange">
            {!next ? (
              <>
                {products.length > 0 ? (
                  <section className="service-section service-page paddingformobile">
                    <div class="div-title elexheads">
                      Choose your old product
                    </div>
                    <div className="thm-container">
                      <div className="row">
                        {products.map(function (item, index) {
                          return (
                            <div
                              key={index}
                              className="col-md-3 col-sm-6 col-6 mobilegrid"
                              //  onClick={() =>
                              //   {fetchProductTypes(item);
                              //     setWorkingConditionAnswerd(false);
                              //   }
                              // }
                            >
                              <div className="single-service-wrapper bg-1">
                                <div className="single-service-content text-center">
                                  <img
                                    src={item.icon}
                                    width="100"
                                    alt={item.name}
                                    id="choose-old-product-img"
                                  />
                                  <h3
                                    className="elexopttitleswithimg"
                                    style={{ marginBottom: "0" }}
                                  >
                                    {item.name}
                                  </h3>

                                  <HashLink to="#old-product-type">
                                    <a
                                      type="button"
                                      className={
                                        product && product.id === item.id
                                          ? "read-more selected"
                                          : "read-more "
                                      }
                                      onClick={() => {
                                        fetchProductTypes(item);
                                        setWorkingConditionAnswerd(false);
                                      }}
                                    >
                                      {/* <i className="fa fa-arrow-right"></i> */}
                                    </a>
                                  </HashLink>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </section>
                ) : (
                  ""
                )}

                {productTypes.length > 0 ? (
                  <section
                    className="service-section service-page service-section-sm paddingformobile"
                    id="old-product-type"
                  >
                    <div class="div-title elexheads">
                      Choose your old product type
                    </div>
                    <div className="thm-container">
                      <div className="row">
                        {productTypes.map(function (item, index) {
                          return (
                            <div
                              key={index}
                              className="col-md-3 col-sm-6 col-6"
                            >
                              <div className="single-service-wrapper bg-1">
                                <div className="single-service-content text-center">
                                  <h5
                                    style={{ marginBottom: "0" }}
                                    className="elexopttitles"
                                  >
                                    {item.name}
                                  </h5>

                                  <HashLink to="#working-condition">
                                    <a
                                      type="button"
                                      className={
                                        productType &&
                                        productType.id === item.id
                                          ? "read-more selected"
                                          : "read-more "
                                      }
                                      onClick={() => {
                                        setProductType(item);
                                        fetchWorkingCondition(item);
                                        setWorkingConditionAnswerd(false);
                                      }}
                                    >
                                      {/* <i className="fa fa-arrow-right"></i> */}
                                    </a>
                                  </HashLink>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </section>
                ) : (
                  ""
                )}

                {productType &&
                workingCondition &&
                workingCondition.length > 0 ? (
                  <div id="working-condition">
                    {workingCondition.map(function (question, questionIndex) {
                      return (
                        <div key={questionIndex}>
                          {questionIndex == 0 ? (
                            <section
                              className="service-section service-page service-section-sm paddingformobile"
                              id={"working-condition-" + questionIndex}
                            >
                              <div class="div-title elexheads">
                                {question.question}
                              </div>
                              <div className="thm-container">
                                <div
                                  className="row"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  {question.answers.map(function (
                                    answer,
                                    answerIndex
                                  ) {
                                    return (
                                      <div
                                        key={answerIndex}
                                        className={
                                          "col-md-" +
                                          12 / question.answers.length +
                                          " fixonmobile"
                                        }
                                      >
                                        <div className="single-service-wrapper bg-1">
                                          <div className="single-service-content text-center">
                                            <h5
                                              style={{ marginBottom: "0" }}
                                              className="elexopttitles"
                                            >
                                              {answer.answer}
                                            </h5>
                                            <HashLink
                                              to={
                                                "#working-condition-" +
                                                (questionIndex + 1)
                                              }
                                            >
                                              <a
                                                type="button"
                                                className={
                                                  workingCondition[
                                                    questionIndex
                                                  ].user_answer ===
                                                  answer.answer
                                                    ? "read-more selected"
                                                    : "read-more "
                                                }
                                                onClick={() => {
                                                  updateWorkingCondition(
                                                    questionIndex,
                                                    answerIndex,
                                                    answer
                                                  );
                                                }}
                                              >
                                                {/* <i className="fa fa-arrow-right"></i> */}
                                              </a>
                                            </HashLink>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </section>
                          ) : (
                            <>
                              {workingCondition[questionIndex - 1]
                                .user_answer &&
                              workingCondition[questionIndex - 1].answers[
                                workingCondition[questionIndex - 1]
                                  .user_answer_key
                              ].allow_other_question == "Yes" ? (
                                <section
                                  className="service-section service-page service-section-sm paddingformobile"
                                  id={"working-condition-" + questionIndex}
                                >
                                  <div class="div-title elexheads">
                                    {question.question}
                                  </div>
                                  <div className="thm-container">
                                    <div
                                      className="row"
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      {question.answers.map(function (
                                        answer,
                                        answerIndex
                                      ) {
                                        return (
                                          <div
                                            key={answerIndex}
                                            className={
                                              "col-md-" +
                                              12 / question.answers.length +
                                              " fixonmobile"
                                            }
                                          >
                                            <div className="single-service-wrapper bg-1">
                                              <div className="single-service-content text-center">
                                                <h5
                                                  className="elexopttitles"
                                                  style={{ marginBottom: "0" }}
                                                >
                                                  {answer.answer}
                                                </h5>
                                                <HashLink
                                                  to={
                                                    "#working-condition-" +
                                                    (questionIndex + 1)
                                                  }
                                                >
                                                  <a
                                                    type="button"
                                                    className={
                                                      workingCondition[
                                                        questionIndex
                                                      ].user_answer ===
                                                      answer.answer
                                                        ? "read-more selected"
                                                        : "read-more "
                                                    }
                                                    onClick={() => {
                                                      updateWorkingCondition(
                                                        questionIndex,
                                                        answerIndex,
                                                        answer
                                                      );
                                                    }}
                                                  >
                                                    {/* <i className="fa fa-arrow-right"></i> */}
                                                  </a>
                                                </HashLink>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                </section>
                              ) : (
                                ""
                              )}
                            </>
                          )}
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
            <section className="case-study-section case-study-page">
              <div className="thm-container">
                <div className="row">
                  <div id="wrapper">
                    <div className="container-fluid pe-0 ps-0">
                      <div className="main-layout">
                        <div className="content-wrapper">
                          {next ? (
                            <div id="back-button" className="content-header">
                              <a
                                href="#"
                                className="btn btn-border-base"
                                onClick={() => {
                                  setNext(false);
                                  setBrand(null);
                                  setBrands([]);
                                  setSubCategories([]);
                                  setSubCategory(null);
                                  setSubCategoryTypes([]);
                                  setSubCategoryType(null);
                                  setBrandProducts([]);
                                  setBrandProduct(null);
                                }}
                              >
                                Go Back
                              </a>
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="content-main">
                            {next ? (
                              <div className="new-products">
                                {subCategories.length > 0 ? (
                                  <section
                                    className="service-section service-page"
                                    id="choose-new-product"
                                  >
                                    <div class="div-title elexheads">
                                      {" "}
                                      Choose the new product
                                    </div>
                                    <div className="thm-container">
                                      <div className="row">
                                        {subCategories.map(function (
                                          item,
                                          index
                                        ) {
                                          return (
                                            <div
                                              key={index}
                                              className="col-md-3 col-sm-6 col-6 mobilegrid"
                                            >
                                              <div className="single-service-wrapper bg-1">
                                                <div className="single-service-content text-center">
                                                  <img
                                                    src={item.icon}
                                                    width="100"
                                                    alt={item.name}
                                                  />
                                                  <h3
                                                    className="elexopttitleswithimg"
                                                    style={{
                                                      marginBottom: "0",
                                                    }}
                                                  >
                                                    {item.name}
                                                  </h3>
                                                  <HashLink
                                                    to={
                                                      item.type
                                                        ? "#choose-new-product-type"
                                                        : "#choose-brand"
                                                    }
                                                  >
                                                    <a
                                                      type="button"
                                                      className={
                                                        subCategory &&
                                                        subCategory.id ===
                                                          item.id
                                                          ? "read-more selected"
                                                          : "read-more "
                                                      }
                                                      onClick={() => {
                                                        setSubCategory(item);
                                                        console.log(item);
                                                        setBrand(null);
                                                        setSubCategoryType(
                                                          null
                                                        );
                                                        setSubCategoryTypes([]);
                                                        setBrandProducts([]);
                                                        setBrands([]);
                                                        if (item.type) {
                                                          fetchSubCategoryTypes(
                                                            item
                                                          );
                                                        } else {
                                                          fetchBrandsViaSubCategory(
                                                            item
                                                          );
                                                        }
                                                      }}
                                                    >
                                                      {/* <i className="fa fa-arrow-right"></i> */}
                                                    </a>
                                                  </HashLink>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  </section>
                                ) : (
                                  ""
                                )}

                                {subCategoryTypes.length > 0 ? (
                                  <section
                                    className="service-section service-page service-section-sm"
                                    id="choose-new-product-type"
                                  >
                                    <div class="div-title elexheads">
                                      Choose new product type
                                    </div>
                                    <div className="thm-container">
                                      <div className="row">
                                        {subCategoryTypes.map(function (
                                          item,
                                          index
                                        ) {
                                          return (
                                            <div
                                              key={index}
                                              className="col-md-3 col-sm-6 col-6 mobilegrid"
                                            >
                                              <div className="single-service-wrapper bg-1">
                                                <div className="single-service-content text-center">
                                                  <h5
                                                    className="elexopttitles"
                                                    style={{
                                                      marginBottom: "0",
                                                    }}
                                                  >
                                                    {item.name}
                                                  </h5>
                                                  <HashLink to="#choose-brand">
                                                    <a
                                                      type="button"
                                                      className={
                                                        subCategoryType &&
                                                        subCategoryType.id ===
                                                          item.id
                                                          ? "read-more selected"
                                                          : "read-more "
                                                      }
                                                      onClick={() => {
                                                        setSubCategoryType(
                                                          item
                                                        );
                                                        fetchBrandsViaSubCategoryType(
                                                          item
                                                        );
                                                        setBrand(null);
                                                        setBrands([]);
                                                        setBrandProducts([]);
                                                        setBrandProduct(null);
                                                      }}
                                                    >
                                                      {/* <i className="fa fa-arrow-right"></i> */}
                                                    </a>
                                                  </HashLink>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  </section>
                                ) : (
                                  ""
                                )}

                                {brands.length > 0 ? (
                                  <section
                                    className="service-section service-page"
                                    id="choose-brand"
                                    style={{ paddingTop: "0" }}
                                  >
                                    <div class="div-title elexheads">
                                      {" "}
                                      Choose the brand you would like to
                                      exchange with
                                    </div>
                                    <div className="thm-container">
                                      <div className="row">
                                        {brands.map(function (item, index) {
                                          return (
                                            <div
                                              key={index}
                                              className="col-md-3 col-sm-6 col-6 mobilegrid"
                                            >
                                              <div className="single-service-wrapper bg-1">
                                                <div className="single-service-content text-center">
                                                  <img
                                                    src={item.image}
                                                    width="100"
                                                    alt={item.name}
                                                  />
                                                  <h3
                                                    style={{
                                                      marginBottom: "0",
                                                    }}
                                                  >
                                                    {/* {item.name} */}
                                                  </h3>
                                                  <HashLink to="#choose-new-model">
                                                    <a
                                                      type="button"
                                                      className={
                                                        brand &&
                                                        brand.id === item.id
                                                          ? "read-more selected"
                                                          : "read-more "
                                                      }
                                                      onClick={() => {
                                                        setBrand(item);
                                                        if (subCategory.type) {
                                                          fetchBrandProductsViaCategoryType(
                                                            item
                                                          );
                                                        } else {
                                                          fetchBrandProductsViaSubCategory(
                                                            item
                                                          );
                                                        }
                                                      }}
                                                    >
                                                      {/* <i className="fa fa-arrow-right"></i> */}
                                                    </a>
                                                  </HashLink>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  </section>
                                ) : (
                                  ""
                                )}

                                {brandProducts.length > 0 ? (
                                  // test

                                  <section
                                    className="service-section service-page"
                                    id="choose-new-model"
                                  >
                                    <div class="div-title elexheads">
                                      Choose Model
                                    </div>
                                    <div className="thm-container">
                                      <div className="row">
                                        {brandProducts.map(function (
                                          item,
                                          index
                                        ) {
                                          return (
                                            <div
                                              // key={index}
                                              className="col-md-3 col-sm-6 col-6 mobilegrid d-flex"
                                            >
                                              <div className="single-service-wrapper bg-1">
                                                <div className="single-service-content text-center">
                                                  <img
                                                    src={item.featured_image}
                                                    width="100"
                                                    alt={item.name}
                                                  />
                                                  <h5
                                                    className="elexopttitleswithimg"
                                                    style={{
                                                      marginTop: "10px",
                                                    }}
                                                  >
                                                    {item.name}{" "}
                                                    <span
                                                      className="view-details"
                                                      style={{
                                                        color: "#1193d4",
                                                      }}
                                                      onClick={() => {
                                                        openModal();
                                                        setBrandProduct(item);
                                                      }}
                                                    >
                                                      <FaEye />
                                                    </span>
                                                  </h5>
                                                  <h6
                                                    className="elexnewprodmodel"
                                                    style={{
                                                      marginBottom: "0",
                                                    }}
                                                  >
                                                    {item.model_number}
                                                  </h6>
                                                  <div
                                                    style={{
                                                      padding:
                                                        "10px 25px 0 25px",
                                                    }}
                                                  >
                                                    <h6 className="elexnewprodprice">
                                                      MRP.{" "}
                                                      <span
                                                        style={{
                                                          color: "#174cbb",
                                                        }}
                                                      >
                                                        {item.price}
                                                      </span>
                                                    </h6>
                                                  </div>

                                                  {brandProduct ? (
                                                    <Modal
                                                      isOpen={modalIsOpen}
                                                      onRequestClose={
                                                        closeModal
                                                      }
                                                      style={customStyles}
                                                      contentLabel="Example Modal"
                                                    >
                                                      <div className="row modalrow">
                                                        <div className="col-lg-6">
                                                          <FaTimes
                                                            onClick={closeModal}
                                                            style={{
                                                              cursor: "pointer",
                                                            }}
                                                          />
                                                          <div
                                                            style={{
                                                              width: "60%",
                                                            }}
                                                          >
                                                            <img
                                                              src={
                                                                brandProduct.featured_image
                                                              }
                                                            />
                                                          </div>
                                                        </div>
                                                        <div
                                                          className="col-lg-6"
                                                          style={{
                                                            background:
                                                              "#9e9e9e14",
                                                            padding: "20px",
                                                            borderRadius:
                                                              "10px",
                                                          }}
                                                        >
                                                          <h2 className="modalprodname ">
                                                            {brandProduct.name}
                                                          </h2>
                                                          <hr />
                                                          <h5 className="modalprodmodel">
                                                            Model:{" "}
                                                            <strong
                                                              style={{
                                                                color:
                                                                  "#246bfd",
                                                              }}
                                                            >
                                                              {
                                                                brandProduct.model_number
                                                              }
                                                            </strong>
                                                          </h5>
                                                          <h5 className="modalprodmodel">
                                                            Brand:{" "}
                                                            <strong
                                                              style={{
                                                                color:
                                                                  "#246bfd",
                                                              }}
                                                            >
                                                              {
                                                                brandProduct.brand_name
                                                              }
                                                            </strong>
                                                          </h5>
                                                          <h5 className="modalprodmodel">
                                                            Price:{" "}
                                                            <strong
                                                              style={{
                                                                color:
                                                                  "#246bfd",
                                                              }}
                                                            >
                                                              {
                                                                brandProduct.price
                                                              }
                                                            </strong>
                                                          </h5>
                                                          <h6
                                                            style={{
                                                              fontWeight:
                                                                "bold",
                                                            }}
                                                            className="modalprodoverview"
                                                          >
                                                            <span
                                                              style={{
                                                                textDecoration:
                                                                  "underline",
                                                              }}
                                                            >
                                                              Quick Overview
                                                            </span>

                                                            <p
                                                              className="quick_overview modalproddesc"
                                                              dangerouslySetInnerHTML={{
                                                                __html:
                                                                  brandProduct.short_description,
                                                              }}
                                                              style={{
                                                                fontSize:
                                                                  "14px",
                                                                lineHeight:
                                                                  "20px",
                                                              }}
                                                            />
                                                          </h6>
                                                        </div>

                                                        <Tab.Container
                                                          defaultActiveKey="first"
                                                          activeKey={currentTab}
                                                          onSelect={(key) =>
                                                            setCurrentTab(key)
                                                          }
                                                          className="nav tabs"
                                                        >
                                                          <Col className="">
                                                            <Nav
                                                              variant="pills"
                                                              className="tab prd-desc"
                                                              align="left"
                                                            >
                                                              <Nav.Item>
                                                                <Nav.Link
                                                                  eventKey="first"
                                                                  className="modaltabbs"
                                                                >
                                                                  Description
                                                                </Nav.Link>
                                                              </Nav.Item>
                                                              <Nav.Item>
                                                                <Nav.Link
                                                                  eventKey="second"
                                                                  className="modaltabbs"
                                                                >
                                                                  Product Specs
                                                                </Nav.Link>
                                                              </Nav.Item>
                                                            </Nav>
                                                          </Col>
                                                          <Tab.Content>
                                                            <Tab.Pane
                                                              eventKey="first"
                                                              style={{
                                                                marginTop:
                                                                  "10px",
                                                                background:
                                                                  "#fff",
                                                                animationDuration:
                                                                  "0.5s",
                                                              }}
                                                            >
                                                              <a
                                                                onClick={
                                                                  handleSelect
                                                                }
                                                              >
                                                                <div
                                                                  className="modalprodlongdescription"
                                                                  dangerouslySetInnerHTML={{
                                                                    __html:
                                                                      brandProduct.long_description,
                                                                  }}
                                                                ></div>
                                                              </a>
                                                            </Tab.Pane>
                                                            <Tab.Pane
                                                              eventKey="second"
                                                              style={{
                                                                marginTop:
                                                                  "10px",
                                                                background:
                                                                  "#fff",
                                                                animationDuration:
                                                                  "0.5s",
                                                              }}
                                                            >
                                                              <p>
                                                                {brandProduct.product_specifications ? (
                                                                  <section className="specs-wrp">
                                                                    <div className="detailspecbox">
                                                                      <table
                                                                        width="100%"
                                                                        border="0"
                                                                        cellSpacing="2"
                                                                        cellPadding="0"
                                                                      >
                                                                        <tbody>
                                                                          <tr>
                                                                            <td>
                                                                              <table
                                                                                width="100%"
                                                                                border="0"
                                                                                cellSpacing="0"
                                                                                cellPadding="0"
                                                                                className="product-spec"
                                                                                itemProp="description"
                                                                              >
                                                                                <tbody>
                                                                                  {brandProduct.product_specifications.map(
                                                                                    function (
                                                                                      product_specifications,
                                                                                      index
                                                                                    ) {
                                                                                      return (
                                                                                        <tr
                                                                                          key={
                                                                                            index
                                                                                          }
                                                                                        >
                                                                                          <td className="specs-heading modalprodlongdescription">
                                                                                            {
                                                                                              product_specifications
                                                                                                .product_feature
                                                                                                .name
                                                                                            }
                                                                                          </td>
                                                                                          <td
                                                                                            itemProp="brand"
                                                                                            className="modalprodlongdescription"
                                                                                            style={{
                                                                                              color:
                                                                                                "#246bfd",
                                                                                            }}
                                                                                          >
                                                                                            {
                                                                                              product_specifications.feature_value
                                                                                            }
                                                                                          </td>
                                                                                        </tr>
                                                                                      );
                                                                                    }
                                                                                  )}
                                                                                </tbody>
                                                                              </table>
                                                                            </td>
                                                                          </tr>
                                                                        </tbody>
                                                                      </table>
                                                                    </div>
                                                                  </section>
                                                                ) : (
                                                                  ""
                                                                )}
                                                              </p>
                                                            </Tab.Pane>
                                                          </Tab.Content>
                                                        </Tab.Container>
                                                      </div>
                                                    </Modal>
                                                  ) : (
                                                    ""
                                                  )}

                                                  <HashLink to="#submission-form">
                                                    <a
                                                      type="button"
                                                      className={
                                                        brandProduct &&
                                                        brandProduct.id ===
                                                          item.id
                                                          ? "read-more selected"
                                                          : "read-more "
                                                      }
                                                      onClick={() =>
                                                        setBrandProduct(item)
                                                      }
                                                    >
                                                      {/* <i className="fa fa-arrow-right"></i> */}
                                                    </a>
                                                  </HashLink>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  </section>
                                ) : (
                                  ""
                                )}
                              </div>
                            ) : (
                              ""
                            )}
                            {/* </form> */}
                          </div>

                          {!next &&
                          workingConditionAnswerd &&
                          product &&
                          productType &&
                          workingCondition ? (
                            <div
                              className="content-footer notfixing paddingformobile"
                              style={{ bottom: "0px" }}
                            >
                              <div className="footer-wrapper">
                                <div className="evaluated-price">
                                  <h5>Old Product</h5>
                                  <span>{product.name}</span>
                                </div>

                                <div className="evaluated-price">
                                  <h5>Old Product Type</h5>
                                  <span>{productType.name}</span>
                                </div>

                                {/* <div className="evaluated-price noarrow">
                                  <h5>Working Status</h5>
                                  <span>
                                    
                                  </span>
                                </div> */}

                                <HashLink
                                  to="#choose-new-product"
                                  className="alignforcontinuebutton"
                                >
                                  <a
                                    type="button"
                                    className="ctn-btn hvr-sweep-to-right"
                                    onClick={() => {
                                      if (
                                        workingCondition &&
                                        productType &&
                                        product
                                      ) {
                                        setNext(true);
                                        fetchSubCategories();
                                        let sum = 0;
                                        for (
                                          let i = 0;
                                          i < workingCondition.length;
                                          i++
                                        ) {
                                          sum +=
                                            workingCondition[i]
                                              .user_answer_price;
                                        }
                                        setExchangePrice(sum);
                                        let offerSum = 0;
                                        for (
                                          let i = 0;
                                          i < workingCondition.length;
                                          i++
                                        ) {
                                          offerSum +=
                                            workingCondition[i]
                                              .user_offer_answer_price;
                                        }
                                        setExchangeOfferPrice(offerSum);
                                      }
                                    }}
                                  >
                                    Continue
                                  </a>
                                </HashLink>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {next && brandProduct ? (
              <section className="login-register" id="submission-form">
                <div className="thm-container">
                  <form onSubmit={handleSubmit}>
                    <div className="title ">
                      <h2>Confirm Your Evaluation</h2>
                    </div>
                    <div
                      className="row form-row formdecor"
                      style={{ boxShadow: "0 0 5px #d7d7d7" }}
                    >
                      <div className="col-md-4">
                        <div style={{ height: "100%" }}>
                          <img src={brandProduct.featured_image} />
                        </div>
                      </div>

                      <div className="col-md-8">
                        <div className="login-box">
                          <h3 style={{ padding: "12px 20px" }}>
                            {brandProduct.name}
                          </h3>
                          <h5 style={{ padding: "0 20px" }}>
                            {brandProduct.model_number}
                          </h5>
                          <hr />
                          <div class="exchange-information">
                            <div class="item">
                              <h3>MRP</h3>
                              <p id="choosen_product_mrp">
                                Rs. {parseFloat(brandProduct.price).toFixed(2)}
                              </p>
                            </div>
                            <hr style={{ borderTop: "dotted" }} />
                            <div
                              class="item highlighted offer"
                              id="additional-discounts"
                            ></div>
                            <div class="item highlighted">
                              <h3>Amount You Pay</h3>
                              <p id="net_payable_price">
                                Rs.{" "}
                                {parseFloat(
                                  brandProduct.exchange_price - exchangePrice
                                ).toFixed(2)}
                              </p>
                            </div>
                            <hr style={{ borderTop: "dotted" }} />
                            <div class="item">
                              <h3>Amount You Save</h3>

                              <p id="amount_save">
                                Rs.{" "}
                                {parseFloat(
                                  brandProduct.price -
                                    (brandProduct.exchange_price -
                                      exchangePrice)
                                ).toFixed(2)}
                              </p>
                            </div>
                            {/* {subCategory.name == "LED TV" &&
                            product.name == "TV" ? (
                              <div>
                                <h2
                                  className="title"
                                  style={{
                                    fontSize: "16px",
                                    textTransform: "uppercase",
                                    padding: "10px",
                                    fontWeight: 800,
                                    borderRadius: "10px 10px 10px 10px ",
                                    backgroundColor: "#cc0041",
                                  }}
                                >
                                  T20 world cup special Offer
                                </h2>
                                <div class="item highlighted">
                                  <h3>Amount You Pay</h3>
                                  <p id="net_payable_price">
                                    Rs.{" "}
                                    {parseFloat(
                                      brandProduct.exchange_price -
                                        exchangeOfferPrice
                                    ).toFixed(2)}
                                  </p>
                                </div>
                                <hr style={{ borderTop: "dotted" }} />
                                <div class="item">
                                  <h3>Amount You Save</h3>

                                  <p id="amount_save">
                                    Rs.{" "}
                                    {parseFloat(
                                      brandProduct.price -
                                        (brandProduct.exchange_price -
                                          exchangeOfferPrice)
                                    ).toFixed(2)}
                                  </p>
                                </div>
                              </div>
                            ) : (
                              ""
                            )} */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="row paddingformobile"
                      style={{ marginTop: "50px" }}
                    >
                      <div className="col-md-3">
                        <div className="register-box">
                          <input
                            type="text"
                            placeholder="Full Name"
                            name="full_name"
                            {...bindFullName}
                          />
                          {errorMessageFullname ? (
                            <>
                              <p style={{ color: "red" }}>
                                {errorMessageFullname}
                              </p>
                            </>
                          ) : (
                            ""
                          )}

                          <input
                            type="text"
                            placeholder="Mobile Number"
                            name="phone_number"
                            {...bindPhone}
                          />
                          {errorMessagePhone ? (
                            <>
                              <p style={{ color: "red" }}>
                                {errorMessagePhone}
                              </p>
                            </>
                          ) : (
                            ""
                          )}

                          {/* <div class="form-check">
                          <input type="checkbox" class="form-check-input" id="i_agree" name="i_agree" />
                            <label id="i-agree-label" class="form-check-label" for="IAgree">I agree<a href="terms-and-conditions" target="_blank" rel="noopener noreferrer"> Terms and Conditions</a></label></div> */}
                        </div>
                      </div>
                      <div className="col-md-3">
                        <input
                          type="text"
                          placeholder="Address"
                          name="address"
                          {...bindAddress}
                        />
                        {errorMessageAddress ? (
                          <>
                            <p style={{ color: "red" }}>
                              {errorMessageAddress}
                            </p>
                          </>
                        ) : (
                          ""
                        )}

                        <input
                          type="text"
                          placeholder="Alternate Mobile Number(optional)"
                          name="altphone_number"
                          {...bindAltPhone}
                        />
                        {/* {errorMessagePhone ? (<><p style={{'color':'red'}}>{errorMessagePhone}</p></>) : ""}   */}
                      </div>
                      <div className="col-md-4">
                        <div className="register-box">
                          <input
                            type="text"
                            placeholder="Email"
                            name="email"
                            {...bindEmail}
                          />
                          {errorMessageEmail ? (
                            <>
                              <p style={{ color: "red" }}>
                                {errorMessageEmail}
                              </p>
                            </>
                          ) : (
                            ""
                          )}

                          <div class="form-check">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              id="i_agree"
                              name="i_agree"
                              onChange={checkboxHandler}
                            />
                            <label
                              id="i-agree-label"
                              class="form-check-label"
                              for="IAgree"
                              style={{ fontSize: "14px" }}
                            >
                              I agree to the{" "}
                              <a
                                href="terms-and-conditions"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: "#289dd8" }}
                              >
                                Terms and Conditions
                              </a>{" "}
                              and{" "}
                              <a
                                href="privacy-policy"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: "#289dd8" }}
                              >
                                Privacy Policy
                              </a>
                              .
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-2">
                        <button
                          type="submit"
                          className="pull-right"
                          style={{ width: "100%", height: "90%" }}
                          disabled={!agree}
                        >
                          Request Exchange
                        </button>
                      </div>
                    </div>

                    <div className="row">
                      <div
                        className="col-md-12"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {successMessage ? (
                          <>
                            <p style={{ color: "green" }}>{successMessage}</p>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </form>
                </div>

                <div className="thm-container" style={{ marginTop: "50px" }}>
                  <div className="content-footer" style={{ bottom: "0px" }}>
                    <div className="footer-wrapper ">
                      <a
                        href="#"
                        className="continue-btn alignforbackbutton"
                        onClick={() => {
                          setNext(false);
                          setBrand(null);
                          setBrands([]);
                          setSubCategories([]);
                          setSubCategory(null);
                          setSubCategoryTypes([]);
                          setSubCategoryType(null);
                          setBrandProducts([]);
                          setBrandProduct(null);
                        }}
                      >
                        Back
                      </a>

                      <div className="new-evaluated-price">
                        <h5>New Brand</h5>
                        <span>{brand.name}</span>
                      </div>

                      <div className="new-evaluated-price">
                        <h5>New Product</h5>
                        <span>{subCategory.name}</span>
                      </div>

                      {subCategoryType ? (
                        <div className="new-evaluated-price">
                          <h5>New Product Type</h5>
                          <span>{subCategoryType.name}</span>
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="new-evaluated-price noarrow">
                        <h5>New Model</h5>
                        <span>{brandProduct.model_number}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            ) : (
              ""
            )}
            {/* {next && brandProduct ? (
                            
                          ) : (
                            ""
                          )} */}
          </div>
        </>
      )}
    </React.Fragment>
  );
};

export default Index;
