import React from "react";

const PrivacyPolicy = () => {
  return (
    <div
      className="container ppmain"
      style={{ marginTop: "50px", marginBottom: "50px" }}
    >
      <h3>Privacy policy</h3>
      <p>
        CG Electronics is committed to protecting your privacy on the Internet.
        We believe that it is important for you to know how we collect, use, and
        share your personal information. When it comes to consumer data
        collected electronically, CG Electronics will provide notice, choice,
        access, and security in ways described below. BY USING THIS WEBSITE, YOU
        CONSENT TO THE TERMS AND CONDITIONS OF THIS PRIVACY POLICY AND TO OUR
        PROCESSING OF PERSONAL INFORMATION FOR THE PURPOSES STATED BELOW. By
        submitting your personal data to us you will be treated as having given
        permission –where necessary and appropriate –for disclosures referred to
        in this policy. IF YOU DO NOT AGREE TO THE TERMS AND CONDITIONS OF THIS
        PRIVACY POLICY, PLEASE DO NOT ACCESS THIS SITE AND THE APPLICATION.
      </p>
      <p>
        The Privacy Policy may be updated and changed from time to time. We
        reserve the right to make changes to this Privacy Policy as required.{" "}
      </p>

      <ol type="A">
        <li>
          <span className="ppheading">Information Collected</span>

          <p>
            This privacy policy applies only to information collected on this
            website and does not apply to information collected by CG
            ELECTRONICS through any other means.
          </p>
          <p>
            We collect two types of information from visitors to this website:
            (1) Personally Identifiable Information; and (2) Non- Personally
            Identifiable Information, such as your IP address or cookies.
          </p>

          <ol type="1">
            <li>
              <span className="ppheading">
                Personally, Identifiable Information
              </span>
              <p>
                "Personally, Identifiable Information" is information that
                identifies you personally, such as your name, address, telephone
                number, email address, or company name. CG Electronics collects
                and stores the personally identifiable information that you
                voluntarily provided to us when you register with our Site or
                App or when you contact us or when you participate on various
                schemes, sales promos or various activities related to the site
                like chatbots and message boards. Here are some examples of
                manners in which we may collect your personally identifiable
                information on this website:
              </p>
              <p>
                We may collect your first and last name and product or service
                details if you contact us with a question related to our
                products or services.
              </p>
              <p>
                We may collect your first and last name and mailing address if
                you request a product on this website; We may collect your name,
                email address or phone number if you contact us with a question.
              </p>
              <p>
                We may collect your first and last name, date of birth and email
                address if you choose to participate in a contest or
                sweepstakes; and/or
              </p>
              <p>
                The above list provides an example of the personally
                identifiable information that may be collected on this website.
                If you do not want CG Electronics to collect your personally
                identifiable information, please do not provide it to us.
              </p>
            </li>
            <li>
              <span className="ppheading">
                Non-Personally Identifiable Information
              </span>
              <p>
                "Non-Personally Identifiable Information" or "Derivative Data"
                can be technical information such as your IP address, your
                browser type, your operating system, your access times, and the
                pages you have viewed directly before or after accessing the
                Site or it can be demographic information, such as your age,
                location, gender or interests. Non-personally identifiable
                information does NOT identify you personally. We may collect
                non-personally identifiable information when you access our Site
                or application. Here are some examples of the non-personally
                identifiable information that is collected via this website:
              </p>
              <p>
                <span className="ppheading">
                  Internet Protocol (IP) address
                </span>{" "}
                -- Your IP address is a number that lets computers attached to
                the Internet know where to send you data -- such as the web
                pages you view.
              </p>
              <p className="ppheading">Cookies - We use cookies.</p>
              <p className="ppheading">User Opt-In and Opt-Out</p>
              <p>
                CG Electronics licenses technology to serve advertisements on
                other Sites and within its content as that content is served
                across the Internet. In addition, CG Electronics may use third
                party network advertisers to serve advertisements and
                third-party analytics vendors to evaluate and provide us with
                information about the use of the Sites and viewing of our
                content. We do not share Personal Data with these third parties,
                but ad network providers, the advertisers, the sponsors, and/or
                analytics service providers may set and access their own
                cookies, pixel tags and similar technologies on your Device and
                they may otherwise collect or have access to data about you,
                including usage data. Third parties that provide marketing
                programs on CG Electronics’ behalf are responsible for
                maintaining both cookie opt-in and opt-out functionality. We and
                our network advertisers may target advertisements for products
                and services in which you might be interested based on your
                visits to both the Sites and other web sites subject to your
                prior opt-in if and when required by the applicable laws.
              </p>
              <p>
                If you prefer to not receive targeted advertising any longer,
                you can opt out of some network advertising programs that use
                your Personal Data. To do so please visit the NAI Opt-Out Page:
                http://www.networkadvertising.org/managing/opt_out.asp.
              </p>
              <p>
                Please note that even if you choose to remove your Personal Data
                (opt out), you might still see advertisements while you're
                browsing online.
              </p>
              <p className="ppheading">Email Opt-Out</p>
              <p>
                From time to time, we communicate with users who subscribe to
                our services via email or text message. For example, we may use
                your email address to confirm your request, to send you
                information about changes to our products and services, and to
                send notices and other disclosures as required by law.
                Generally, users cannot opt-out of these communications, but
                they will be primarily informational in nature rather than
                promotional. However, we provide you the opportunity to exercise
                an opt-out choice if you do not want to receive other types of
                communication from us, such as emails or updates from us
                regarding new services and products offered on this website or
                if you do not want us to share your personally identifiable
                information with third parties. The opt-out choice may be
                exercised by ticking or un-ticking the appropriate box if such
                checkbox is available at the points where personally
                identifiable information is collected or by contacting us. We
                will process you unsubscribe as soon as possible, but please be
                aware that in some circumstances you may receive a few more
                messages until the unsubscribe is processed. You also may
                opt-out of receiving such emails by clicking on the
                "unsubscribe" link within the text of the email.
              </p>
            </li>
            <li>
              <span className="ppheading">
                Data collection through social sites.
              </span>

              <p>
                CG ELECTRONICS may offer you the possibility to connect your
                accounts from third party social networking sites, such as
                Facebook and Twitter (each, a “Social Networking Site”), with
                the Services by an application programming interface or other
                software. If you allow CG ELECTRONICS to connect the Services
                with your accounts on such Social Networking Sites, we may
                access your account information and other information, which may
                include Personal Data, from such Social Networking Sites. In
                addition, we may collect, use and store such information as set
                forth in this Privacy Policy and we may combine this information
                with other information in our records. While your account from
                such Social Networking Site is connected to the Services, we can
                display on the Services information about your social networking
                activity and account. It is your choice whether to use any such
                Social Networking Site, and you understand that in some
                instances such Social Networking Sites may request permission to
                access and publish information about you or your friends that is
                included in your CG ELECTRONICS account.{" "}
              </p>
              <p>
                To protect against potential fraud, we may verify with third
                parties the information collected from these Sites. In the
                course of such verification, we may receive personally
                identifiable information about you from such services. Except as
                described in this Privacy Policy or at the time we request the
                information, we do not otherwise use, share or otherwise
                disclose your personally identifiable information to any third
                parties.
              </p>

              <ol type="A">
                <li>
                  <span className="ppheading">Use of Information</span>
                  <p>How will CG ELECTRONICS use the information it obtains?</p>
                  <p>
                    We use your information that is collected through the Sites
                    primarily for the following purposes:
                  </p>
                  <ul>
                    <li>To deliver or provide the service;</li>
                    <li>To provide customer service;</li>
                    <li>
                      To alert you of special offers, updated information, other
                      new products or service, or to forward promotional
                      materials to you;
                    </li>
                    <li>
                      To complete a transaction or service requested by you;
                    </li>
                    <li>To fulfill the terms of a promotion;</li>
                    <li>
                      To improve Sites and ensure the Sites are relevant to your
                      needs;
                    </li>
                    <li>
                      {" "}
                      To help us create and publish content most relevant to
                      you;
                    </li>
                    <li>
                      To notify you about a material change to this Privacy
                      Policy or the Terms of Use, if necessary;
                    </li>
                    <li>
                      To allow you access to limited-entry areas of the Sites;
                    </li>
                    <li>
                      To allow you to upload content or to add a comment to the
                      Sites;
                    </li>
                    <li>
                      To contact you in response to sign up forms such as
                      “Contact Us” or other inquiries.
                    </li>
                    <li>
                      To analyze, review, and improve the products or service we
                      offer;
                    </li>
                    <li>To understand how customers are using the Sites</li>
                    <li>To develop new products and service we offer;</li>
                    <li>
                      To provide you with a user-friendly navigation experience;
                    </li>
                    <li>To create and manage your account;</li>
                    <li>
                      To protect and defend our rights and property, including
                      by way of legal proceedings;
                    </li>
                    <li>
                      To assist law enforcement and comply with regulatory
                      requirements; for marketing;
                    </li>
                    <li>
                      To compile statistical date and make analysis for use
                      internally or with third parties; and for internal record
                      keeping
                    </li>
                  </ul>
                  <p>
                    If accept to provide your Information by accessing to this
                    Site, CG Electronics shall use this information only for the
                    purpose disclosed in this Privacy Policy as amended from
                    time to time. CG Electronics will not sell, rent or
                    otherwise disclose that information to third parties unless
                    such disclosure is necessary for the purposes set forth in
                    this Policy, by law or a policy or notice contained or
                    associated with a specific Site(s) and/or Service(s).
                  </p>
                </li>
                <li>
                  <span className="ppheading">Information Sharing</span>
                  <p>
                    We may share information we have collected in certain
                    situations as follows:
                  </p>
                  <ul>
                    <li>
                      To our parent company, subsidiaries and affiliates CG
                      ELECTRONICS may have to share your data with these parties
                      in order to provide you with services that have been
                      requested by you, or so that we can ensure our services
                      and products are meeting your needs. For example, in some
                      instances CG ELECTRONICS may be required to transfer your
                      data to our parent company situated at Kathmandu or Nepal,
                      in order to provide you with a product or service
                      requested by you. The term ‘affiliates’ or ‘subsidiaries’
                      refers to group companies who may be using CG name or who
                      has common ownership or control such as CG, or CG
                      Electronics.
                    </li>
                    <li>
                      We generally do not share with third parties the
                      information we receive as a result of you using the Sites.
                      Nevertheless, please be aware of the following
                      circumstances where we may share such information with the
                      following entities
                    </li>
                  </ul>
                  <br />
                  <p className="ppheading">Third Party Service Provider.</p>
                  <ul>
                    <li>
                      If you purchase or download an item from a site, we may
                      share your data in order to provide the item to you. In
                      addition, if you email us a question, we may use your
                      email address to process your request and respond to your
                      question. In addition, if you purchase a product via the
                      Sites, we may need to share your data with third parties
                      in order to have that product delivered to you and
                      installed as you requested. Also, if you are entering a
                      competition or prize draw, we may use your data in order
                      to fulfill the terms of that promotion.
                    </li>
                    <li>
                      To unaffiliated third-party service providers, agents or
                      independent contractors who help us maintain our Sites and
                      provide other administrative services to us (including,
                      but not limited to, order processing and fulfillment,
                      providing customer service, maintaining and analyzing
                      data, sending customer communications on CG ELECTRONICS's
                      behalf, and entry collection, winner selection and prize
                      fulfillment for contests, sweepstakes and other
                      promotions). We seek to ensure that these unaffiliated
                      third parties will not use the data for any other purpose
                      than to provide the administrative services for which they
                      are responsible. Because such unaffiliated third-party
                      service providers that help us administer our Sites will
                      have access to users' data, if you do not wish for our
                      unaffiliated third-party service providers to have access
                      to your information, please do not register or submit any
                      Personally Identifiable Information to us.
                    </li>
                  </ul>
                  <p>
                    If you choose to make a purchase on the Sites, we may
                    forward you to our sister concern as CG Digital’s outlet or
                    ecommerce channel cgdigital.com.np for further transactions.
                  </p>
                  <ul>
                    <li>
                      Other Parties When Required by Law. As required by court
                      order or other government or law enforcement authorities
                      in order to comply with legal process or law;
                    </li>
                    <li>
                      Other Parties in Connection with Corporate Transactions.
                      In connection with a corporate event, such as a merger or
                      sale of all or part of CG ELECTRONICS business (including
                      that of a division or subsidiary), where CG ELECTRONICS
                      may need to disclose information to a potential buyer and
                      its professional advisers;
                    </li>
                    <li>
                      Other Parties When it is Necessary to Protect Our Service.
                      In order to protect and defend our rights and property,
                      including by way of legal proceedings;
                    </li>
                    <li>
                      Other Parties When it is to Protect Any of Our Users’
                      Personal Safety or Property. In order to protect the
                      personal safety or property of our users or the public
                    </li>
                  </ul>
                  <p>
                    To strategic partners, agents, third party marketers or
                    other unaffiliated parties who are offering products or
                    services that we believe may be of interest to you. These
                    parties may use your personally identifiable information to
                    contact you with an offer or advertisement related to a
                    product or service. In this case, we may ask your additional
                    consent.
                  </p>
                  <p className="ppheading">Overseas Transfers</p>
                  <p>
                    We, our affiliates, subsidiaries, and suppliers may use
                    information (including your personal information) in
                    countries other than where you are located in connection
                    with providing you with our service and any other purposes
                    outlined in this Privacy Policy. By agreeing to this Privacy
                    Policy, you expressly consent to us and our business
                    associates and suppliers processing your data in any
                    jurisdiction, including, without limitation, Republic of
                    Korea and the United States in accordance with this Privacy
                    Policy. Your right to protection of personal information
                    shall be governed by the terms of this Policy and the
                    prevailing laws of Nepal.
                  </p>
                  <p className="ppheading">
                    How long will CG ELECTRONICS keep my personal Information
                  </p>
                  <p>
                    We will take reasonable steps to make sure that we keep your
                    personal information for as long as it is necessary for us
                    to provide you with using the Sites or other purposes set
                    out in this Privacy Policy.
                  </p>
                  <p className="ppheading">Location-Based Services</p>
                  <p>
                    We may collect, use, and share (with CG Electronics’
                    partners, service providers and licensees) precise location
                    data, including the real-time location of your mobile or
                    fixed location device.
                  </p>
                  <p className="ppheading">Analytics Tools</p>
                  <p>
                    We use analytics tools and other third-party technologies,
                    such as Google Analytics and DoubleClick Cookies, to collect
                    non-personal information in the form of various usage and
                    user metrics when you use our online Sites and/or Services.
                    These tools and technologies collect and analyze certain
                    types of information, including cookies, IP addresses,
                    device and software identifiers, referring and exit URLs,
                    onsite behavior and usage information, feature use metrics
                    and statistics, usage and purchase history, MAC Address,
                    mobile unique device ID, and other similar information.
                  </p>
                  <p>
                    The third-party analytics companies who collect information
                    on our Sites and/or Services and other online products
                    and/or services may combine the information collected with
                    other information they have independently collected from
                    other websites and/or other online or mobile products and
                    services relating to your activities across their network of
                    websites as well as online and/or mobile products and
                    services. Many of these companies collect and use
                    information under their own privacy policies
                  </p>
                  <p>
                    In addition to our use of technologies as described herein,
                    we may permit certain third party companies to help us
                    tailor advertising that we think may be of interest to you
                    based on your use of CG Electronics Sites and/or Services
                    and to otherwise collect and use data about your use of CG
                    Electronics Sites and/or Services. For more information
                    about this practice, please see the "Third Party Advertising
                    Technologies" section below.
                  </p>
                  <p>
                    You may opt out of the DoubleClick cookie by visiting the
                    Google advertising opt-out page or you may opt out of Google
                    Analytics by visiting the Google Analytics opt-out page
                  </p>
                  <p>
                    Google has additional information available about their
                    Remarketing Privacy Guidelines, and Restrictions.
                  </p>
                  <p className="ppheading">
                    Third Party Advertising Technologies
                  </p>
                  <p>
                    In addition to using cookies and related technologies as
                    described above, we also may permit certain third-party
                    companies to help us tailor advertising that we think may be
                    of interest to users and to collect and use other data about
                    user activities on our Sites and/or Services (e.g., to allow
                    them to tailor ads on third party services). These companies
                    may deliver ads that might also place cookies and otherwise
                    track user behavior. These companies may use information
                    about user behavior in order to provide customized
                    advertisements across various services and products. In the
                    course of providing these services, products or placing
                    advertisements, these third-party companies may place or
                    recognize a unique cookie on your computer, and may record
                    information to these cookies based upon your activities on
                    any of our Sites and/or Services and on third party
                    websites. Each of these companies uses this cookie
                    information according to their own privacy and security
                    policies. If you wish to not have this information used for
                    the purpose of serving you targeted ads, you may opt-out as
                    indicated in this Policy. Please note this does not opt you
                    out of being delivered advertising. You will continue to
                    receive generic ads.
                  </p>
                  <p>
                    If you do not want CG Electronics to have access to this
                    information, please do not visit our Sites.
                  </p>
                </li>
                <li>
                  <span className="ppheading">
                    Collection and Use of Information from Children Under the
                    Age of 18
                  </span>
                  <p>
                    CG Electronics does not knowingly collect or solicit
                    Personal Information from anyone under the age of 18 or
                    knowingly allow such persons to use this website. If you are
                    under 18 please do not attempt to register on this website
                    or send us any Personal Information.
                  </p>
                </li>
                <li>
                  <span className="ppheading">
                    Forums, Chat Rooms and Other Public Posting Areas
                  </span>
                  <p>
                    Please note that any information you include in a message
                    you post to any board, chat room, forum or other public
                    posting area is available to anyone with Internet access. If
                    you don't want people to know your e-mail address, for
                    example, don't include it in any message you post publicly.
                  </p>
                </li>
                <li>
                  <span className="ppheading">Third Party Web Sites</span>
                  <p>
                    This statement applies solely to information collected on
                    this website. This website may contain links to other web
                    sites. We are not responsible for the privacy practices or
                    the content of these other web sites.
                  </p>
                </li>
                <li>
                  <span className="ppheading">Assignment</span>
                  <p>
                    In the event that all or part of our assets are sold or
                    acquired by another party, or in the event of a merger, you
                    grant us the right to assign the personally identifiable and
                    non-personally identifiable information collected via this
                    website.
                  </p>
                </li>
                <li>
                  <span className="ppheading">
                    Changes to this Privacy Policy
                  </span>
                  <p>
                    We reserve the right to change this privacy policy from time
                    to time in our sole discretion.{" "}
                  </p>
                </li>
                <li>
                  <span className="ppheading">Security</span>
                  <p>
                    No data transmissions over the Internet can be guaranteed to
                    be 100% secure. Consequently, we cannot ensure or warrant
                    the security of any information you transmit to us and you
                    understand that any information that you transfer to CG
                    Electronics is done at your own risk.
                  </p>
                  <p>
                    Once we receive your transmission, we make reasonable
                    efforts to ensure security on our systems. We use firewalls
                    to protect your information from unauthorized access,
                    disclosure, alteration, or destruction. However, please note
                    that this is not a guarantee that such information may not
                    be accessed, disclosed, altered or destroyed by breach of
                    such firewalls and secure server software.
                  </p>
                  <p>
                    If we learn of a security system breach, we may attempt to
                    notify you electronically so that you can take appropriate
                    protective steps. By using these Sites or providing
                    personally identifiable information to us you agree that we
                    can communicate with you electronically regarding security,
                    privacy and administrative issues relating to your use of
                    these Sites. We may post a notice on our Sites if a security
                    breach occurs. We may also send an email to you at the email
                    address you have provided to us in these circumstances.
                    Depending on where you live, you may have a legal right to
                    receive notice of a security breach in writing.
                  </p>
                  <p>
                    As we value your personal information, we will ensure an
                    adequate level of protection. As mentioned above we have
                    therefore implemented technology and policies with the
                    objective of protecting your privacy from unauthorized
                    access and improper use and will update these measures as
                    new technology becomes available, as appropriate.
                  </p>
                </li>
                <li>
                  This Privacy Policy, including all revisions and amendments
                  thereto, is governed by and construed in accordance with the
                  laws of Nepal, without regard to its conflict or choice of law
                  principles.
                </li>
                <li>
                  <span className="ppheading">No indemnification </span>
                  <p>
                    CG Electronics shall have no obligation to indemnify any
                    person in connection with any matter related to or arising
                    out of this Policy or the subject matter of this Policy
                    except for any willful misconduct adjudicated by the
                    competent court.{" "}
                  </p>
                </li>
                <li>
                  If you feel that the CG Electronics web site
                  (http://www.electronics.com.np) is not abiding by its posted
                  privacy policy, please contact Webmaster of CG Electronics on
                  CG Electronics Pvt. Ltd., CG Digital Park, Satungal,
                  Kathmandu, Nepal Post box: 1073 Post code: 44619 +977 01
                  5108122 Ext: 2747 9851273416, 9851211507. All information you
                  provide in any written communication will also be covered by
                  this policy.
                  <p className="ppheading">
                    <br />
                    SMS, Whatsapp, Viber & E-Mails Users
                  </p>
                  <p>
                    By agree of this privacy policy; you provide us all rights
                    to text messages on your phone number, Whatsapp messages,
                    Viber messages and e-mail you anytime through social
                    engagements like Facebook, Twitter, Whatsapp, Viber and
                    through cellular networks to enhance our services towards
                    you, related to this business.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </li>
      </ol>
    </div>
  );
};

export default PrivacyPolicy;
