import React from "react";
import { FaArrowRight } from "react-icons/fa";
import "../cgelex.css";

const TermsnConditionsEMI = () => {
  return (
    <div
      className="container"
      style={{ marginTop: "50px", marginBottom: "50px" }}
    >
      <h3>Terms and conditions for EMI</h3>

      <ol
        class="tnc"
        style={{ fontSize: "15px", lineHeight: "25px", color: "black" }}
      >
        <li> This is the EMI calculator and not the final Quotation.</li>
        <li> Need to visit the shop location for EMI Process.</li>
        <li>
          Partner Bank or Finance Institute have the final decision on the EMI
          amount approval.
        </li>
        <li>
          Must need to provide the valid documents, as per the request and
          requirement by Finance institute.
        </li>
        <li>
          The availability of the product(s) selected by the user of CG ELEX is
          conditional upon the inventory at the chosen store. Availability may
          fluctuate at the chosen store by the user.{" "}
        </li>
      </ol>
    </div>
  );
};

export default TermsnConditionsEMI;
