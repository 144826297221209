import ContactAreaFour from "components/ContactAreaFour";
import React from "react";

const CustomerRegistration = () => {
  return (
    <>
      {/* Contact Main */}
      <ContactAreaFour />
    </>
  );
};

export default CustomerRegistration;
