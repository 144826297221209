import React from 'react'

const NotFound = () => {
  return (
    <div className="thm-container" style={{ display:"flex",justifyContent:"center" }} >
      <img src="assets/img/404.png" style={{ width:"50%", marginTop: "50px", marginBottom: "50px" }} />
    </div>
  )
}

export default NotFound;