import React from "react";

const ContactAreaTwo = () => {
  return (
    <>
      {/* ========================= contact Area Two start =========================*/}
      <div className='contact-area' style={{ paddingTop: "100px"}}>
        <div className='container'>
          <div className='contact-inner-1'>
            <div className='row mt-5'>
              <div className='row'>
                <div className='col-lg-12'>
                <h6 className='sub-title'>Frequently Asked Questions</h6>
                  {/* <h2 className='title ftproductname'>Frequently Asked Questions</h2> */}
           
                </div>
              </div>
              <div
                className='col-lg-6'
                data-aos='fade-right'
                data-aos-delay='200'
                data-aos-duration='1500'
              >
                {/* <img className='w-100' src='assets/img/service/bigbanner.png' alt='img' /> */}
                
                  <div
                    className='accordion accordion-inner style-3 accordion-icon-left mt-3'
                    id='accordionExample'
                  >
                    <div className='accordion-item'>
                      <h2 className='accordion-header' id='headingOne'>
                        <button
                          className='accordion-button collapsed'
                          type='button'
                          data-bs-toggle='collapse'
                          data-bs-target='#collapseOne'
                          aria-expanded='false'
                          aria-controls='collapseOne'
                        >
                        What is the exchange program for pre-owned products?
                        </button>
                      </h2>
                      <div
                        id='collapseOne'
                        className='accordion-collapse collapse'
                        aria-labelledby='headingOne'
                        data-bs-parent='#accordionExample'
                      >
                        <div className='accordion-body'>
                        The exchange program for pre-owned products allows customers to trade in their used items for credit towards the purchase of new products.
                        </div>
                      </div>
                    </div>
                    <div className='accordion-item'>
                      <h2 className='accordion-header' id='headingTwo'>
                        <button
                          className='accordion-button collapsed'
                          type='button'
                          data-bs-toggle='collapse'
                          data-bs-target='#collapseTwo'
                          aria-expanded='false'
                          aria-controls='collapseTwo'
                        >
                        How does the exchange process work for pre-owned items?
                        </button>
                      </h2>
                      <div
                        id='collapseTwo'
                        className='accordion-collapse collapse'
                        aria-labelledby='headingTwo'
                        data-bs-parent='#accordionExample'
                      >
                        <div className='accordion-body'>
                        To avail of the best exchange offer, visit (Exchange Link) and select the appropriate questionnaire, marking the condition of your pre-owned product.
                        </div>
                      </div>
                    </div>
                    <div className='accordion-item'>
                      <h2 className='accordion-header' id='headingThree'>
                        <button
                          className='accordion-button collapsed'
                          type='button'
                          data-bs-toggle='collapse'
                          data-bs-target='#collapseThree'
                          aria-expanded='false'
                          aria-controls='collapseThree'
                        >
                         What types of products are eligible for exchange?
                        </button>
                      </h2>
                      <div
                        id='collapseThree'
                        className='accordion-collapse collapse'
                        aria-labelledby='headingThree'
                        data-bs-parent='#accordionExample'
                      >
                        <div className='accordion-body'>
                        All brands and types of CRT, LED/LCD TVs, refrigerators, air conditioners, and washing machines are eligible for exchange.
                        </div>
                      </div>
                    </div>
                    <div className='accordion-item'>
                      <h2 className='accordion-header' id='headingFour'>
                        <button
                          className='accordion-button collapsed'
                          type='button'
                          data-bs-toggle='collapse'
                          data-bs-target='#collapseFour'
                          aria-expanded='false'
                          aria-controls='collapseFour'
                        >
                      How can I determine the exchange value of my pre-owned product?
                        </button>
                      </h2>
                      <div
                        id='collapseFour'
                        className='accordion-collapse collapse'
                        aria-labelledby='headingFour'
                        data-bs-parent='#accordionExample'
                      >
                        <div className='accordion-body'>
                        The value of the pre-owned product is determined by its size & physical condition.
                        </div>
                      </div>
                    </div>
                    </div>
              </div>
              <div
                className='col-lg-6'
                data-aos='fade-left'
                data-aos-delay='200'
                data-aos-duration='1500'
              >
                  
                  <div
                    className='accordion accordion-inner style-3 accordion-icon-left mt-3'
                    id='accordionExample1'
                  >
                    
                    <div className='accordion-item'>
                      <h2 className='accordion-header' id='headingFive'>
                        <button
                          className='accordion-button collapsed'
                          type='button'
                          data-bs-toggle='collapse'
                          data-bs-target='#collapseFive'
                          aria-expanded='false'
                          aria-controls='collapseFive'
                        >
                 Can I exchange multiple pre-owned products in a single transaction?
                        </button>
                      </h2>
                      <div
                        id='collapseFive'
                        className='accordion-collapse collapse'
                        aria-labelledby='headingFive'
                        data-bs-parent='#accordionExample'
                      >
                        <div className='accordion-body'>
                        Please note that the exchange program does not allow multiple products to be exchanged in a single transaction.
                        </div>
                      </div>
                    </div>
                    <div className='accordion-item'>
                      <h2 className='accordion-header' id='headingSix'>
                        <button
                          className='accordion-button collapsed'
                          type='button'
                          data-bs-toggle='collapse'
                          data-bs-target='#collapseSix'
                          aria-expanded='false'
                          aria-controls='collapseSix'
                        >
                        Can I cancel or modify the exchange request after initiating the process?
                        </button>
                      </h2>
                      <div
                        id='collapseSix'
                        className='accordion-collapse collapse'
                        aria-labelledby='headingSix'
                        data-bs-parent='#accordionExample'
                      >
                        <div className='accordion-body'>
                        Yes, you can cancel the exchange request before any transaction is completed with the retailer.
                        </div>
                      </div>
                    </div>
                    <div className='accordion-item'>
                      <h2 className='accordion-header' id='headingSeven'>
                        <button
                          className='accordion-button collapsed'
                          type='button'
                          data-bs-toggle='collapse'
                          data-bs-target='#collapseSeven'
                          aria-expanded='false'
                          aria-controls='collapseSeven'
                        >
                     	Are there any additional charges or fees associated with the exchange?
                        </button>
                      </h2>
                      <div
                        id='collapseSeven'
                        className='accordion-collapse collapse'
                        aria-labelledby='headingSeven'
                        data-bs-parent='#accordionExample'
                      >
                        <div className='accordion-body'>
                        There are no extra charges for the exchange process.
                        </div>
                      </div>
                    </div>
                    <div className='accordion-item'>
                      <h2 className='accordion-header' id='headingEight'>
                        <button
                          className='accordion-button collapsed'
                          type='button'
                          data-bs-toggle='collapse'
                          data-bs-target='#collapseEight'
                          aria-expanded='false'
                          aria-controls='collapseEight'
                        >
                      What is the warranty coverage for the new product received through exchange?
                        </button>
                      </h2>
                      <div
                        id='collapseEight'
                        className='accordion-collapse collapse'
                        aria-labelledby='headingEight'
                        data-bs-parent='#accordionExample'
                      >
                        <div className='accordion-body'>
                      All new products obtained through the exchange program come with the standard company warranty.
                        </div>
                      </div>
                    </div>
                  </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*========================= contact-inner Two end =========================*/}
    </>
  );
};

export default ContactAreaTwo;
