import React, { useState } from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import "../cgelex.css";

export const Ticker = ({ className, ...rest }) => {
  const [viewPortEntered, setViewPortEntered] = useState(false);

  return (
    <CountUp {...rest} start={viewPortEntered ? null : 0}>
      {({ countUpRef }) => {
        return (
          <VisibilitySensor
            active={!viewPortEntered}
            onChange={(isVisible) => {
              if (isVisible) {
                setViewPortEntered(true);
              }
            }}
            delayedCall
          >
            <h4 className={className} ref={countUpRef} />
          </VisibilitySensor>
        );
      }}
    </CountUp>
  );
};

const AboutAreaOne = () => {
  return (
    <>
      {/* ================== AboutAreaOne start  ==================*/}
      <div className="about-area pd-top-120">
        <div className="container">
          <div className="row">
            <div
              className="count-up mb-5"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <div className="each-count-up">
                <Ticker className="count" end={10} suffix="+" />
                <span>BRANDS</span>
              </div>
              <div className="each-count-up">
                <Ticker className="count" end={40} suffix="+" />
                <span>YEARS OF EXPERIENCE</span>
              </div>
              <div className="each-count-up">
                <Ticker className="count" end={1700} suffix="+" />
                <span>DEALERS AND DISTRIBUTORS</span>
              </div>
              <div className="each-count-up">
                <Ticker className="count" end={2000} suffix="+" />
                <span>RANGE OF PRODUCTS</span>
              </div>
              <div className="each-count-up">
                <Ticker className="count" end={40} suffix="+" />
                <span>SERVICE CENTERS</span>
              </div>
              <div className="each-count-up">
                <Ticker className="count" end={2} suffix="+" />
                <span>PRODUCTION UNITS</span>
              </div>
            </div>

            <div
              className="col-lg-12 "
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-title mt-5 mt-lg-0">
                <h6 className="sub-title">About Us</h6>
                {/* <h2 className="title">
                  <span>CG Electronics</span>
                </h2> */}
                <p className="content">
                  <strong>CG Digital Pvt. Ltd. </strong>is Nepal's first
                  state-of-art model one stop multi-brand electronics online
                  eCommerce website. CG Digital Pvt. Ltd got its birth with the
                  purpose of providing world class electronics brands' products
                  under one roof.
                </p>
                <p className="content">
                  CG Digital Pvt. Ltd is one of the leading names in the field
                  of consumer electronics, information technology, home
                  appliances and entertainment in Nepal and is amongst the first
                  transnational corporations with a well spread out network of
                  <strong> 18 retail stores</strong>, more coming up shortly and
                  service centers across Nepal.CG Digital Pvt. Ltd with
                  established systems and procedures which are constantly tested
                  and improved upon to best serve customer needs.
                </p>

                <p className="content">
                  <strong>VISION</strong>
                </p>
                <p className="content">
                  To be the number one in retail field in consumer electronics
                  and to become a globally recognized company through
                  innovation, passion through quality, freedom through
                  empowerment, and cost through volumes. We should imbibe a
                  world class system to bring in delight to all our associates
                  and the society at large.
                </p>
                <p className="content">
                  <strong>MISSION</strong>
                </p>
                <p className="content">
                  To create unique customer satisfaction through innovation,
                  quality, productivity, human resource development, freedom
                  through empowerment, continuously striving for excellence with
                  pride in our values and confidence in our approach.
                </p>
                <p className="content">
                  <strong>TARGET CONSUMERS</strong>
                </p>
                <p className="content">
                  Working class who are time conscious and prefer branded
                  products all under one roof Quality conscious buyers
                </p>
                <p className="content">
                  <strong>SCOPE</strong>
                </p>
                <p className="content">
                  To start “one-stop-shop” outlets from capital city, Kathmandu
                  and spread to entire country within a span of 2 years.
                </p>

                <p className="content mb-2">
                  <strong>
                    The salient features of the showroom are as follows:
                  </strong>
                </p>
                <ul>
                  <li>Personalized services</li>
                  <li>Over 1000 SKU of the best brands.</li>
                  <li>Free home delivery* and installation</li>
                  <li>Easy installments</li>
                  <li>World class service centers</li>
                  <li>Warranty</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              {/* <div
                className='about-thumb-inner pe-xl-5 me-xl-5 '
                data-aos='fade-right'
                data-aos-delay='100'
                data-aos-duration='1500'
              >
                <img
                  className='animate-img-1 top_image_bounce'
                  src='assets/img/about/2.png'
                  alt='img'
                />
                <img
                  className='animate-img-2 left_image_bounce'
                  src='assets/img/about/3.png'
                  alt='img'
                />
                <img
                  className='animate-img-3 top_image_bounce'
                  src='assets/img/banner/5.svg'
                  alt='img'
                />
                <img
                  className='main-img'
                  src='assets/img/about/1.png'
                  alt='img'
                />
              </div> */}

              {/* <div className="row">
                <div class="col-6">
                  <div class="single-thumb">
                    <img alt="Image not found" src="assets/img/about/cg1.jpg" />
                  </div>

                  <div class="single-thumb">
                    <img alt="Image not found" src="assets/img/about/cg1.jpg" />
                  </div>
                </div>
                <div class="col-6">
                  <div class="single-thumb">
                    <img alt="Image not found" src="assets/img/about/cg3.jpg" />
                  </div>

                  <div class="single-thumb">
                    <img alt="Image not found" src="assets/img/about/cg4.jpg" />
                  </div>
                </div>
              </div> */}
            </div>
          </div>

          {/* edited

          <div className="row">
            <p className="content mb-4 mb-xl-5">
              CG Electronics provides consumers free after-sales service with a
              toll-free number, 2-1-1 service concept with 13 own service
              centers, dedicated go-to-market along with dedicated B2B team for
              business-to-business operations and CAC team for Commercial Air
              Conditioners facilities, who are ready to be deployed whenever
              customers needs. Being the only Nepali company to have an
              e-commerce platform, products and services from CG Electronics
              will be accessible even if service centers or showrooms are not
              available nearby. Currently, CG Electronics has 19 showrooms
              spread across the Nepal with ultimate goal of reaching every
              corner with best products and service.
            </p>
          </div>

          <div className="row" style={{ padding: "50px" }}>
            <div className="col-md-6">
              <div className="single-about-inner">
                <div className="thumb mb-3">
                  <img src="assets/img/icon/vision.png" alt="img" />
                </div>
                <div className="details">
                  <h5>Vision</h5>
                  <p>
                    To become Nepal’s most admired company in terms of
                    innovation, professionalism & fair-play in consumer
                    durables.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="single-about-inner">
                <div className="thumb mb-3">
                  <img src="assets/img/icon/mission.png" alt="img" />
                </div>
                <div className="details">
                  <h5>Mission</h5>
                  <p>
                    <ul>
                      <li>
                        Inject feeling of Ownership within all associates.
                      </li>
                      <li>
                        Acquisition, Develop, Motivate & Retain The Best Talents
                        in country.
                      </li>
                      <li>
                        Integrate our channel partners in order to generate
                        Synergy effect.
                      </li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div class="core-values-section row-centered">
              <div class="core-head justify-content-center mbr-white row">
                <h2 style={{ textAlign: "center" }}>
                  Core Values &amp; Beliefs
                </h2>

                <div class="col-12">
                  <div class="display-7 mbr-fonts-style">&nbsp;</div>
                </div>
              </div>

              <div class="row upp">
                <div class="col-md-3 col-sm-3 upp1">
                  <div class="hexagon">
                    <img
                      alt="Art"
                      src="https://cgelectronics.com.np/ckfinder/userfiles/files/cv1.png"
                    />
                    <h3>enterpreneurship</h3>

                    <p>
                      Be a part of the solution and innovate. Look at problems
                      as opportunities. Take calculated chances after assessing
                      risks.
                    </p>

                    <h5>Henry Ford</h5>

                    <p>(1863-1947)</p>
                  </div>
                </div>

                <div class="col-md-3 col-sm-3 upp2">
                  <div class="hexagon">
                    <img
                      alt="Art"
                      src="https://cgelectronics.com.np/ckfinder/userfiles/files/cv2.png"
                    />
                    <h3>humility</h3>

                    <p>
                      Listen to everyone: ask what you don’t know. Let your work
                      speak for you.
                    </p>

                    <h5>Mahatma Gandhi</h5>

                    <p>(1849-1948)</p>
                  </div>
                </div>

                <div class="col-md-3 col-sm-3 upp3">
                  <div class="hex1 hexagon">
                    <img
                      alt="Art"
                      src="https://cgelectronics.com.np/ckfinder/userfiles/files/cv3.png"
                    />
                    <h3>learning</h3>

                    <p>
                      Learn from people, situations and experiences to improve
                      yourself and the business. Seek feedback from your team
                      members, superiors and other stakeholders.
                    </p>

                    <h5>Albert Einstein</h5>

                    <p>(1879-1955)</p>
                  </div>
                </div>
              </div>

              <div class="low row">
                <div class="col-md-3 col-sm-3 low1">
                  <div class="hexagon">
                    <img
                      alt="Art"
                      src="https://cgelectronics.com.np/ckfinder/userfiles/files/cv4.png"
                    />
                    <h3>social responsibility</h3>

                    <p>
                      Strive for stretch goals. While meeting timeliness be
                      dependable and punctual and if there is delay, keep
                      concerned members informed. Take responsibility.
                    </p>

                    <h5>Mother Teresa</h5>

                    <p>(1910-1997)</p>
                  </div>
                </div>

                <div class="col-md-3 col-sm-3 low2">
                  <div class="hexagon">
                    <img
                      alt="Art"
                      src="https://cgelectronics.com.np/ckfinder/userfiles/files/cv5.png"
                    />
                    <h3>respect the individual</h3>

                    <p>
                      Be punctual, coutreous and polite to everyone. Always have
                      a good word for your team members and other stakeholders
                      and reward them suitably.
                    </p>

                    <h5>Martin Luther King Jr.</h5>

                    <p>(1929-1968)</p>
                  </div>
                </div>

                <div class="col-md-3 col-sm-3 low3">
                  <div class="hex1 hexagon">
                    <img
                      alt="Art"
                      src="https://cgelectronics.com.np/ckfinder/userfiles/files/cv6.png"
                    />
                    <h3>deliver the promise</h3>

                    <p>
                      Strive for stretch goals. While meeting timelines be
                      dependable and punctual and if there is delay, keep
                      concerned members informed. Take responsibility.
                    </p>

                    <h5>Abharam Linchon</h5>

                    <p>(1809-1865)</p>
                  </div>
                </div>

                <div class="col-md-3 col-sm-3 low4">
                  <div class="hex1 hexagon">
                    <img
                      alt="Art"
                      src="https://cgelectronics.com.np/ckfinder/userfiles/files/cv7.png"
                    />
                    <h3>teamwork &amp; relationships</h3>

                    <p>
                      Promote effective team participants. Try for the best
                      solution that benefits all. Discourage protection of
                      department/ function at the cost of business.
                    </p>

                    <h5>Tenzing Norgay</h5>

                    <p>(1914-1986)</p>

                    <h5>Edmund Hilary</h5>

                    <p>(1919-2008)</p>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      {/* ================== AboutAreaOne End  ==================*/}
    </>
  );
};

export default AboutAreaOne;
