import React, { useEffect, useState } from "react";
import ExchangeCodeModal from "components/ExchangeCodeModal";
import { useLocation } from "react-router-dom";

import { Navigate, useNavigate } from "react-router-dom";

const ExchangeCode = (props) => {
  const [code, setCode] = useState(null);
  const [data, setData] = useState(null);
  const [expiryText, setExpiryText] = useState(null);
  const Navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (location.state) {
      setCode(location.state.code);
      setExpiryText(location.state.expiry_text);
      setData(location.state.data);
    } else {
      Navigate("/");
    }
  }, []);
  return (
    <div>
      <ExchangeCodeModal code={code} expiryText={expiryText} data={data} />
    </div>
  );
};

export default ExchangeCode;
