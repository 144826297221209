import React from "react";
import { FaExchangeAlt } from "react-icons/fa";
import { Link } from "react-router-dom";

import { HashLink } from "react-router-hash-link";
import ExchangeCarousel from "./ExchangeCarousel";

const BannerFour = () => {
  return (
    <>
      {/* ================== BannerFour Start ==================*/}
      <div className="banner-area bg-relative banner-area-3">
        <div className="container custom-container">
          <div className="bg-gray">
            <Link to="/exchange">
              {/* <img
              className='img-right'
              src='assets/img/banner-one.jpeg'
              alt='img'
              style={{ width:"100% !important" }}
            /> */}
            </Link>
            <ExchangeCarousel />

            {/* <div className='container'>
              <div className='row'>
                <div className='col-lg-7 align-self-center'>
                  <div className='banner-inner'>
                    <h6
                      className='subtitle '
                      data-aos='fade-right'
                      data-aos-delay='100'
                      data-aos-duration='1500'
                    >
                      OLD FOR NEW
                    </h6>
                    <h2
                      className='title '
                      data-aos='fade-right'
                      data-aos-delay='250'
                      data-aos-duration='1500'
                    >
                      Upgrade your lifestyle and step-in into the world of <span>CGELEX</span>.
                    </h2>
                    <HashLink
                      className='mt-3 btn btn-border-base'
                      data-aos='fade-right'
                      data-aos-delay='400'
                      data-aos-duration='1500'
                      to='/exchange'
                    >
                      Exchange Now <FaExchangeAlt />
                    </HashLink>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      {/* ================== BannerFour End ==================*/}
    </>
  );
};

export default BannerFour;
