import React, { useState } from "react";
import Modal from "react-modal";
import test_qr from "../test_qr.png";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import Download from "Download.gif";
import "../cgelex.css";

const ExchangeCodeModal = (props) => {
  const generatePDF = async () => {
    // const report = new JsPDF('portrait','px','a4');

    // const report = new JsPDF({
    //   orientation: "portrait",
    //   unit: "px",
    //   format: "a4"
    // });
    // report.html(document.querySelector('#report')).then(() => {
    //     report.save('report.pdf');
    // });

    const pdf = new jsPDF("portrait", "pt", "a4");
    const data = await html2canvas(document.querySelector("#pdf"));
    const img = data.toDataURL("image/png");
    const imgProperties = pdf.getImageProperties(img);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("exchange.pdf");
  };

  return (
    <>
      {/* <div >

<img src={Download} alt="download" onClick={generatePDF} style={{cursor:"pointer",marginLeft:"5%"}}/>
    </div> */}

      {props.data ? (
        <div style={{ marginTop: "10px" }}>
          <div id="pdf" className="pd-phone">
            <div
              className="container mb-5 excode-width"
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                padding: "20px",
              }}
            >
              <div className="column">
                <h3>Congratulations!</h3>
                <span style={{ color: "black", fontWeight: "bold" }}>
                  Your exchange code has been created.
                </span>
                <br />
                <span style={{ fontSize: "14px" }}>
                  Please provide the exchange code during your exchange at our
                  authorized store.
                </span>
                {/* <div className='mt-3' style={{width:"20%"}}>
   <img src={test_qr} alt="" className='w-100'/>
 3
   </div> */}
                <h4 style={{ color: "green", marginTop: "20px" }}>
                  CODE:{" "}
                  <span
                    style={{
                      background: "black",
                      background: "#eaeaea",
                      padding: "5px",
                    }}
                  >
                    {props.code}
                  </span>
                </h4>

                <table
                  style={{
                    marginTop: "20px",
                    width: "100%",
                    borderCollapse: "collapse",
                  }}
                >
                  <tr style={{ padding: "10px" }}>
                    <td
                      style={{
                        background: "#eaeaea",
                        padding: "0 5px",
                        border: "solid thin #7777773b",
                        fontSize: "14px",
                        textAlign: "left",
                        width: "150px",
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      Exchange Product
                    </td>
                    <td
                      style={{
                        padding: "0 5px",
                        border: "solid thin #7777773b",
                      }}
                    >
                      <span style={{ fontSize: "12px" }}>
                        {props.data["old_product"]["product"]}
                      </span>
                    </td>
                    <td
                      style={{
                        padding: "0 5px",
                        border: "solid thin #7777773b",
                      }}
                    >
                      <span style={{ fontSize: "12px" }}>
                        {props.data["old_product"]["product_type"]}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        background: "#eaeaea",
                        padding: "0 5px",
                        border: "solid thin #7777773b",
                        fontSize: "14px",
                        textAlign: "left",
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      New Product
                    </td>
                    <td
                      style={{
                        padding: "0 5px",
                        border: "solid thin #7777773b",
                      }}
                    >
                      <span style={{ fontSize: "12px" }}>
                        {props.data["new_product"]["product"]}
                      </span>
                    </td>
                    <td
                      style={{
                        padding: "0 5px",
                        border: "solid thin #7777773b",
                      }}
                    >
                      <span style={{ fontSize: "12px" }}>
                        {props.data["new_product"]["model_number"]}
                      </span>
                    </td>
                  </tr>
                </table>

                <table
                  style={{
                    marginTop: "20px",
                    width: "100%",
                    borderCollapse: "collapse",
                  }}
                >
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        style={{
                          background: "#eaeaea",
                          padding: "0 5px",
                          border: "solid thin #7777773b",
                          fontSize: "14px",
                          textAlign: "left",
                          width: "150px",
                          fontWeight: "bold",
                          color: "black",
                        }}
                      >
                        Conditions of old Product
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.data["conditions"].map(function (item, index) {
                      return (
                        <>
                          {item.answer ? (
                            <tr key={index}>
                              <td
                                style={{
                                  padding: "0 5px",
                                  border: "solid thin #7777773b",
                                  textAlign: "left",
                                }}
                              >
                                <p
                                  style={{
                                    fontSize: "12px",
                                    marginBottom: "0 !important",
                                    borderBottom: "solid thin #7777773b",
                                  }}
                                >
                                  {item.question}:{" "}
                                  <strong>{item.answer}</strong>
                                </p>
                              </td>
                            </tr>
                          ) : (
                            ""
                          )}
                        </>
                      );
                    })}
                  </tbody>
                </table>

                <table
                  style={{
                    marginTop: "20px",
                    width: "100%",
                    borderCollapse: "collapse",
                  }}
                >
                  <tr>
                    <th
                      style={{
                        background: "#eaeaea",
                        padding: "0 5px",
                        border: "solid thin #7777773b",
                        fontSize: "14px",
                        textAlign: "left",
                        width: "150px",
                        fontWeight: "bold",
                        color: "black",
                      }}
                      colspan="3"
                    >
                      Price Summary
                    </th>
                    <td
                      style={{
                        padding: "0 5px",
                        border: "solid thin #7777773b",
                      }}
                    >
                      <span style={{ fontSize: "12px" }}>
                        MRP:{" "}
                        <strong>
                          NPR. {props.data["price_summary"]["mrp"]}
                        </strong>{" "}
                      </span>
                    </td>

                    <td
                      style={{
                        padding: "0 5px",
                        border: "solid thin #7777773b",
                      }}
                    >
                      <span style={{ fontSize: "12px" }}>
                        Payable Amount:{" "}
                        <strong>
                          NPR. {props.data["price_summary"]["payable_amount"]}
                        </strong>{" "}
                      </span>
                    </td>

                    <td
                      style={{
                        padding: "0 5px",
                        border: "solid thin #7777773b",
                      }}
                    >
                      <span style={{ fontSize: "12px" }}>
                        You Save:{" "}
                        <strong>
                          NPR.
                          {parseFloat(
                            props.data["price_summary"]["saving_amount"]
                          ).toFixed(2)}
                        </strong>{" "}
                      </span>
                    </td>
                  </tr>
                </table>
                <div>
                  <div
                    style={{
                      marginTop: "20px",
                      backgroundColor: "#8080802b",
                      padding: "20px",
                      borderRadius: "5px",
                      textAlign: "left",
                    }}
                  >
                    <span style={{ fontSize: "14px" }}>{props.expiryText}</span>
                    <br />
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "black",
                        fontSize: "14px",
                      }}
                    >
                      Document Required
                    </span>
                    <ol>
                      <li>
                        <span style={{ fontSize: "14px" }}>
                          Copy of citizenship/driving license.
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "14px" }}>pp size photo.</span>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <a
            onClick={generatePDF}
            type="button"
            style={{ marginLeft: "20px" }}
            className="floatingmenu"
          >
            Export PDF
          </a>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default ExchangeCodeModal;
