import React from 'react';
import "../submitted.css";


const SubmittedPage = () => {
  return (
    <div style={{display:"flex",flexDirection:"column"}}>
<div class="wrapper" > <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"> <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/> <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
</svg>

    <div className='success-body'>
<h4 className='success-header'>

  <span className='success-header-each'>Thank</span>
  <span className='success-header-each'>You.</span>
  <span className='success-header-each'>Your</span>
  <span className='success-header-each'>Request</span>
  <span className='success-header-each'>Has</span>
  <span className='success-header-each'>Been</span>
  <span className='success-header-each'>Submitted.</span>
</h4>


    </div>
    </div>
</div>
  )
}

export default SubmittedPage
