import React, { useEffect, useState } from "react";
import CouponCodeModal from 'components/CouponCodeModal'
import { useLocation } from 'react-router-dom'

import { Navigate, useNavigate } from "react-router-dom";

const CouponCode = (props) => {
  // alert(props.location.state.code);
  const [loyaltyRegistration, setLoyaltyRegistration] = useState(null);
  const [warrantyRegistration, setWarrantyRegistration] = useState(null);
  const Navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if(location.state){
      setLoyaltyRegistration(location.state.loyalty_registration);
      setWarrantyRegistration(location.state.warranty_registration);
    }else{
      Navigate('/');
    }
    
  }, []);
  return (
    <div>
  
      <CouponCodeModal loyaltyRegistration={loyaltyRegistration} warrantyRegistration={warrantyRegistration} />
  
    </div>
  )
}

export default CouponCode
