import React, { useEffect, useState } from "react";
import "../cgelex.css";
import GoogleMapReact from "google-map-react";
import Data from "./utils/data";
import api from "./utils/api";

const storeData = [
  {
    province: "Province 1",
    districts: [
      {
        name: "P1 D1",
        cities: ["C1", "C2"],
      },
      {
        name: "P1 D2",
        cities: ["C3", "C4"],
      },
      {
        name: "P1 D3",
        cities: ["C5", "C6"],
      },
    ],
  },
  {
    province: "Province 2",
    districts: [
      {
        name: "P2 D1",
        cities: ["C7", "C8"],
      },
      {
        name: "P2 D2",
        cities: ["C9", "C10"],
      },
      {
        name: "P2 D3",
        cities: ["C11", "C12"],
      },
    ],
  },
];

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const StoreOne = () => {
  const [isLoading, setIsloading] = useState(true);
  const [province, setProvince] = useState("");
  const [district, setDistrict] = useState("");
  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [brand, setBrand] = useState("");
  const [brands, setBrands] = useState([]);
  const [stores, setStores] = useState([]);
  const [provinceErr, setProvinceErr] = useState("");
  const [districtErr, setDistrictErr] = useState("");
  const [brandErr, setBrandErr] = useState("");
  const [search, setSearch] = useState(false);

  const icon = {
    url: Data.API_BASE_URL + "/img/point_logo.png", // url
    // scaledSize: new google.maps.Size(30, 30), // scaled size
  };

  function fetchProvinces() {
    fetch(Data.API_BASE_URL_CRM + "exchange/provinces", {
      method: "GET",
    })
      .then(api.handleResponseAndError)
      .then((response) => {
        setProvinces(response);
        setIsloading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function fetchDistricts(provinceId) {
    setDistricts([]);
    fetch(Data.API_BASE_URL_CRM + "exchange/districts/" + provinceId, {
      method: "GET",
    })
      .then(api.handleResponseAndError)
      .then((response) => {
        setDistricts(response);
        setIsloading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function fetchBrands() {
    fetch(Data.API_BASE_URL_CRM + "exchange/brands", {
      method: "GET",
    })
      .then(api.handleResponseAndError)
      .then((response) => {
        setBrands(response);
        setIsloading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function fetchStores() {
    setProvinceErr("");
    setDistrictErr("");
    setBrandErr("");

    let formError = false;

    if (province == "") {
      setProvinceErr("Please select province");
      formError = true;
    }
    if (district == "") {
      setDistrictErr("Please select district");
      formError = true;
    }
    if (brand == "") {
      setBrandErr("Please select brand");
      formError = true;
    }

    if (formError == false) {
      setSearch(true);
      setStores([]);
      fetch(
        Data.API_BASE_URL_CRM +
          "exchange/outlets/" +
          district +
          "?brand_id=" +
          brand,
        {
          method: "GET",
        }
      )
        .then(api.handleResponseAndError)
        .then((response) => {
          console.log(response);
          setStores(response);
          setIsloading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  useEffect(() => {
    fetchProvinces();
    fetchBrands();
  }, []);

  const defaultProps = {
    center: {
      lat: 27.669872,
      lng: 85.281463,
    },
    zoom: 11,
  };
  return (
    <div className="about-area pd-top-60 pd-bottom-60">
      <div className="container">
        <div className="row">
          <h2 className="store-title">Find Store</h2>
        </div>
        {/* search html */}
        <div
          className="search col-md-12 row"
          style={{ display: "flex", alignItems: "center" }}
        >
          <div className="col-lg-3">
            <select
              className="select_edit"
              onChange={(e) => {
                setProvince(e.target.value);
                fetchDistricts(e.target.value);
                setDistrict(null);
                setStores([]);
                setSearch(false);
                if (e.target.value != "") {
                  setProvinceErr("");
                }
              }}
            >
              <option value="">Select Province</option>
              {provinces.map(function (item, index) {
                return (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
            </select>
            {provinceErr ? (
              <>
                <p style={{ color: "red" }}>{provinceErr}</p>
              </>
            ) : (
              ""
            )}
          </div>

          <div className="col-lg-3">
            <select
              className="select_edit"
              onChange={(e) => {
                setStores([]);
                setDistrict(e.target.value);
                setSearch(false);
                if (e.target.value != "") {
                  setDistrictErr("");
                }
              }}
            >
              <option value="">Select District</option>
              {districts.map(function (item, index) {
                return (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
            </select>
            {districtErr ? (
              <>
                <p style={{ color: "red" }}>{districtErr}</p>
              </>
            ) : (
              ""
            )}
          </div>

          <div className="col-lg-3">
            <select
              className="select_edit"
              onChange={(e) => {
                setStores([]);
                setBrand(e.target.value);
                setSearch(false);
                if (e.target.value != "") {
                  setBrandErr("");
                }
              }}
            >
              <option value="">Select Brand</option>
              {brands.map(function (item, index) {
                return (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
            </select>
            {brandErr ? (
              <>
                <p style={{ color: "red" }}>{brandErr}</p>
              </>
            ) : (
              ""
            )}
          </div>

          <div className="col-lg-3">
            <button onClick={fetchStores} className="btn btn-success btn-sm">
              Search
            </button>
          </div>

          <div className="col-lg-4" style={{ display: "flex" }}>
            {/* <button type="submit" className="btn btn-primary store-btn" style={{marginLeft:"50%"}}><i className="fa fa-search"  id='store_search'> </i> Search</button> */}
          </div>
        </div>

        {search ? (
          <>
            {stores.length > 0 ? (
              <div className="col-lg-12  row mt-3">
                {/* <div className='col-lg-9'>
        <div style={{ height: '400px', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        <AnyReactComponent
          lat={27.669872}
          lng={85.281463}
          text="My Marker"
        />
      </GoogleMapReact>
    </div>
      </div> */}
                <div
                  className="col-lg-12"
                  style={{ height: "400px", overflowY: "scroll" }}
                >
                  <h4 className="store_lists">Search Results</h4>
                  <div
                    className="storelists_detail"
                    style={{
                      fontSize: "0.8rem",
                      fontWeight: "800",
                      color: "#545454",
                    }}
                  >
                    {stores.map(function (item, index) {
                      return (
                        <>
                          <div
                            className="storelist_each"
                            style={{ marginTop: "20px" }}
                          >
                            <i className="fa fa-user"></i>
                            <span style={{ marginLeft: "10px" }}>
                              {item.name}
                            </span>
                          </div>
                          <div className="storelist_each">
                            <i
                              className="fa fa-phone"
                              style={{ transform: "rotateZ(90deg)" }}
                            ></i>
                            <span style={{ marginLeft: "10px" }}>
                              {item.address}
                            </span>
                          </div>
                          <div
                            className="storelist_each"
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <i className="fa fa-envelope"></i>
                            <span style={{ marginLeft: "10px" }}>
                              {item.contact_person}
                            </span>
                          </div>
                          <div className="storelist_each">
                            <i
                              className="fa fa-phone"
                              style={{ transform: "rotateZ(90deg)" }}
                            ></i>
                            <span style={{ marginLeft: "10px" }}>
                              {item.mobile_number}
                              {item.landline_number
                                ? ", " + item.landline_number
                                : ""}
                            </span>
                          </div>
                          <div
                            className="storelist_each"
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <i className="fa fa-envelope"></i>
                            <span style={{ marginLeft: "10px" }}>
                              {item.email}
                            </span>
                          </div>
                          <hr />
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            ) : (
              <>
                {district ? (
                  <div
                    className="col-lg-12"
                    style={{ height: "400px", overflowY: "scroll" }}
                  >
                    <h4 className="store_lists">Search Results</h4>
                    No Data Found
                  </div>
                ) : (
                  ""
                )}
              </>
            )}
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default StoreOne;
