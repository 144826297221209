import React, { useRef, useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Download from "Download.gif";
import ReactToPDF from "react-to-pdf";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { FaSearch } from "react-icons/fa";

const EmiCodeModal = (props) => {
  const pdfRef = useRef();
  // const generatePDF = async () => {
  //   const pdf = new jsPDF("portrait", "pt", "a4");

  //   const data = await html2canvas(document.querySelector("#pdf"));
  //   const img = data.toDataURL("image/png");
  //   const imgProperties = pdf.getImageProperties(img);
  //   const pdfWidth = pdf.internal.pageSize.getWidth();
  //   const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
  //   pdf.addImage(img, "PNG", 30, 30, pdfWidth, pdfHeight);

  //   pdf.save("emireport.pdf");
  // };
  const generatePDF = () => {
    const input = pdfRef.current;
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4", true);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const imgX = (pdfWidth - imgWidth * ratio) / 2;
      const imgY = 50;

      pdf.addImage(
        imgData,
        "PNG",
        imgX,
        imgY,
        imgWidth * ratio,
        imgHeight * ratio,
        {
          preserveAspectRatio: true,
        }
      );
      pdf.setProperties({
        background: {
          color: "#ffffff !important",
        },
      });
      pdf.save("emireport.pdf");
    });
  };

  return (
    <>
      <div>
        <div class="floatingmenu" onClick={generatePDF}>
          <img
            src={Download}
            alt=""
            style={{ width: "20px", backgroundColor: "green" }}
          />
          &nbsp; &nbsp; Download
        </div>
        <div ref={pdfRef}>
          {props.data ? (
            <div className="pdf-to-print">
              <div style={{ marginTop: "10px" }}>
                <div className="pd-phone">
                  <div
                    className="container mb-5 excode-width"
                    style={{
                      textAlign: "center",
                      boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                      padding: "20px",
                    }}
                  >
                    <div className="column">
                      <h3>Congratulations!</h3>
                      <span style={{ color: "black", fontWeight: "bold" }}>
                        Your EMI code has been created.
                      </span>
                      <br />
                      <span style={{ fontSize: "14px" }}>
                        Please provide the emi code during your purchase from
                        our authorized store.
                      </span>
                      <h4 style={{ color: "green", marginTop: "20px" }}>
                        CODE:
                        <span
                          style={{
                            background: "black",
                            background: "#eaeaea",
                            padding: "5px",
                          }}
                        >
                          {props.data.emi_code}
                        </span>
                      </h4>
                      <div className="emiproductflex">
                        <div className="emiproductsubflex emiflexhead col-12 col-sm-3 col-lg-3">
                          EMI Product
                        </div>
                        <div className="emiproductsubflex col-12 col-sm-6 col-lg-6">
                          {props.data["product"]["product_name"]}
                        </div>
                        <div className="emiproductsubflex col-12 col-sm-3 col-lg-3">
                          {props.data["product"]["model_number"]}
                        </div>
                      </div>
                      {/* <div className="dealer-ko-info">
                    <div className="dealer-ko-info-head">Dealer Info</div>
                    <div className="dealer-ko-info-sub-head">
                      <span className="emicode-sub-columns ">
                        Address:{""}
                        <strong>
                          {props.data["dealer_details"]["dealer_address"]}
                        </strong>
                      </span>
                    </div>
                    <div className="dealer-ko-info-sub-head">
                      <span className="emicode-sub-columns ">
                        Contact:{""}
                        <br />
                        <strong>
                          {props.data["dealer_details"]["dealer_contact"]}
                        </strong>
                      </span>
                    </div>
                    <div className="dealer-ko-info-sub-head">
                      <span className="emicode-sub-columns ">
                        Email:{""}
                        <strong>
                          {props.data["dealer_details"]["dealer_email"]}
                        </strong>
                      </span>
                    </div>
                    <div className="dealer-ko-info-sub-head">
                      <span className="emicode-sub-columns ">
                        Name:{""}
                        <strong>
                          {props.data["dealer_details"]["dealer_name"]}
                        </strong>
                      </span>
                    </div>
                  </div> */}
                      <div className=" emiproductflex">
                        <div className="emiproductsubflex emiflexhead col-12 col-md-2 col-lg-2">
                          Emi Details
                        </div>
                        <div className="emiproductsubflex col-12 col-md-2 col-lg-2">
                          <span className="emicode-sub-columns ">
                            Bank Name:{""}
                            <strong>
                              {props.data["emi_details"]["bank_name"]}
                            </strong>
                          </span>
                        </div>
                        <div className="emiproductsubflex col-12 col-md-2 col-lg-2">
                          <span className="emicode-sub-columns ">
                            Down Payment:{""}
                            <strong>
                              NPR.
                              {props.data["emi_details"]["down_payment_amount"]}
                            </strong>
                          </span>
                        </div>
                        <div className="emiproductsubflex col-12 col-md-2 col-lg-2">
                          <span className="emicode-sub-columns ">
                            Emi Price:{""}
                            <strong>
                              NPR.
                              {props.data["emi_details"]["emi_price"]}
                            </strong>
                          </span>
                        </div>
                        <div className="emiproductsubflex col-12 col-md-2 col-lg-2">
                          <span className="emicode-sub-columns ">
                            Emi Period:{""}
                            <strong>
                              {props.data["emi_details"]["emi_period"]} months
                            </strong>
                          </span>
                        </div>
                        <div className="emiproductsubflex col-12 col-md-2 col-lg-2">
                          <span className="emicode-sub-columns ">
                            Emi Premium:{""}
                            <strong>
                              NPR.
                              {props.data["emi_details"]["emi_installment"]}
                            </strong>
                            {/* {props.data["price_summary"]["emi_premium"]} */}
                          </span>
                        </div>
                      </div>
                      {/* <table
                    style={{
                      marginTop: "20px",
                      width: "100%",
                      borderCollapse: "collapse",
                    }}
                  >
                    <tr>
                      <td
                        style={{
                          background: "#eaeaea",
                          padding: "0 5px",
                          border: "solid thin #7777773b",
                          fontSize: "12px",
                          textAlign: "left",
                          fontWeight: "bold",
                          color: "black",
                        }}
                      >
                        Emi Details
                      </td>
                      <td
                        style={{
                          padding: "0 5px",
                          border: "solid thin #7777773b",
                        }}
                      >
                        <span className="emicode-sub-columns ">
                          Bank Name:{""}
                          <strong>
                            {props.data["emi_details"]["bank_name"]}
                          </strong>
                        </span>
                      </td>
                      <td
                        style={{
                          padding: "0 5px",
                          border: "solid thin #7777773b",
                        }}
                      >
                        <span className="emicode-sub-columns ">
                          Emi Period:{""}
                          <strong>
                            {props.data["emi_details"]["emi_period"]} months
                          </strong>
                        </span>
                      </td>
                      <td
                        style={{
                          padding: "0 5px",
                          border: "solid thin #7777773b",
                        }}
                      >
                        <span className="emicode-sub-columns ">
                          Partner Bank Id:{""}
                          <strong>
                            {props.data["emi_details"]["partner_bank_id"]}
                          </strong>
                        </span>
                      </td>
                    </tr>
                  </table> */}
                      <div>
                        <div
                          style={{
                            marginTop: "20px",
                            backgroundColor: "aliceblue",
                            padding: "20px",
                            borderRadius: "5px",
                            textAlign: "left",
                          }}
                        >
                          <span
                            style={{
                              fontWeight: "bold",
                              color: "#08bae9",
                              fontSize: "14px",
                            }}
                          >
                            EMI Terms & Conditions
                          </span>
                          <div style={{ fontSize: "12px" }}>
                            <ol>
                              <li>
                                <span>
                                  This is the EMI calculator and not the final
                                  Quotation.
                                </span>
                              </li>
                              <li>
                                <span>
                                  Need to visit the shop location for EMI
                                  Process.
                                </span>
                              </li>
                              <li>
                                <span>
                                  Partner Bank or Finance Institute have the
                                  final decision on the EMI amount approval.
                                </span>
                              </li>
                              <li>
                                <span>
                                  Must need to provide the valid documents, as
                                  per the request and requirement by Finance
                                  institute.
                                </span>
                              </li>
                              <li>
                                <span>
                                  The availability of the product(s) selected by
                                  the user of CG ELEX is conditional upon the
                                  inventory at the chosen store. Availability
                                  may fluctuate at the chosen store by the user.
                                </span>
                              </li>
                            </ol>
                          </div>
                        </div>
                      </div>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <span className="findstore-btn">
          <a href="/store" className="finding-nearest-store" target="_blank">
            <FaSearch /> Find the nearest store
          </a>
        </span>
      </div>
    </>
  );
};

export default EmiCodeModal;
