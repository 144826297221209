import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Data from "./utils/data";
import api from "./utils/api";
import "../cgelex.css";
import Modal from "react-modal";
import { FaTimes } from "react-icons/fa";
import { Tab, Col, Nav, Form } from "react-bootstrap";
import { useLocation } from "react-router-dom";

const SearchResults = (props) => {
  const [products, setProducts] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState(null);
  const [currentTab, setCurrentTab] = useState("first");
  const location = useLocation();

  const handleSelect = (eventKey) => {
    setCurrentTab("second");
  };
  function fetchSearchData() {
    setSearch(getSearchData());

    let url = new URL(Data.API_BASE_URL + "exchange/search-products/" + search);
    fetch(url, {
      method: "GET",
    })
      .then(api.handleResponseAndError)
      .then((response) => {
        setProducts(response);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        // history.push("/page-not-found");
      });
  }

  const { params } = useParams();
  function getSearchData() {
    return location.state.search_keyword;
  }

  //modal
  Modal.setAppElement("#root");

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      zIndex: "99999",
      width: "80%",
      height: "80%",
    },
    overlay: { zIndex: 1000 },
  };

  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  // modal ends

  useEffect(() => {
    setProducts([]);
    fetchSearchData();
  }, [search]);

  return (
    <React.Fragment>
      {loading == false ? (
        <>
          {products.length > 0 ? (
            <div
              className="blog-area pt-4 pd-bottom-90"
              style={{ background: "#f1f1f1" }}
            >
              <div className="container">
                <div className="row">
                  <h3 style={{ color: "grey" }}>Search Results: "{search}"</h3>
                  {products.map(function (item, index) {
                    return (
                      <div
                        key={index}
                        className="col-lg-3 col-md-6 col-sm-6 col-6 mobilegrid"
                      >
                        <div className="single-blog-list">
                          <div
                            className="thumb"
                            onClick={() => {
                              setProduct(item);
                              openModal();
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <img
                              className="border-radius-5"
                              src={item.featured_image}
                              alt="img"
                            />
                          </div>
                          <div className="details">
                            {/* <p className="cat">NEW</p> */}
                            <h5
                              className="mb-3 ftproductname"
                              onClick={() => {
                                setProduct(item);
                                openModal();
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              {/* <Link
                          target="_blank"
                          to={
                            Data.BASE_URL+"product-" +
                            item.name
                              .replace(/[#+. _/]/gi, "-")
                              .toLowerCase() +
                            "-" +
                            item.id
                          }
                        > */}
                              {item.name}
                              {/* </Link> */}
                            </h5>
                            <p className="date ftproductmodel">
                              {item.model_number}
                            </p>
                            {/* <Link 
                      target="_blank"
                        className="read-more-text"
                        to={
                          Data.BASE_URL+"product-" +
                          item.name
                            .replace(/[#+. _/]/gi, "-")
                            .toLowerCase() +
                          "-" +
                          item.id
                        }
                      > */}
                            <span className="ftproductmrp">
                              MRP. {item.price}
                            </span>
                            {/* </Link> */}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>

                {product ? (
                  <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                  >
                    <div className="row modalrow">
                      <div className="col-lg-6">
                        <FaTimes
                          onClick={closeModal}
                          style={{ cursor: "pointer" }}
                        />
                        <div style={{ width: "60%" }}>
                          <img className="w-100" src={product.featured_image} />
                        </div>
                      </div>
                      <div
                        className="col-lg-6"
                        style={{
                          background: "rgba(158, 158, 158, 0.08)",
                          padding: "20px",
                          borderRadius: "10px",
                        }}
                      >
                        <h2 class="modalprodname ">{product.name}</h2>
                        <hr />
                        <h5 class="modalprodmodel">
                          Model:{" "}
                          <strong style={{ color: "rgb(36, 107, 253)" }}>
                            {product.model_number}
                          </strong>
                        </h5>
                        <h5 class="modalprodmodel">
                          Brand:{" "}
                          <strong style={{ color: "rgb(36, 107, 253)" }}>
                            {product.brand_name}
                          </strong>
                        </h5>
                        <h5 class="modalprodmodel">
                          Price:{" "}
                          <strong style={{ color: "rgb(36, 107, 253)" }}>
                            {product.price}
                          </strong>
                        </h5>
                        <h6
                          class="modalprodoverview"
                          style={{ fontWeight: "bold" }}
                        >
                          <span style={{ textDecoration: "underline" }}>
                            Quick Overview
                          </span>

                          <p
                            className="quick_overview modalproddesc"
                            dangerouslySetInnerHTML={{
                              __html: product.short_description,
                            }}
                            style={{ fontSize: "14px", lineHeight: "20px" }}
                          />
                        </h6>
                      </div>
                      <Tab.Container
                        defaultActiveKey="first"
                        activeKey={currentTab}
                        onSelect={(key) => setCurrentTab(key)}
                        className="nav tabs"
                      >
                        <Col className="">
                          <Nav
                            variant="pills"
                            className="tab prd-desc"
                            align="left"
                          >
                            <Nav.Item>
                              <Nav.Link eventKey="first" className="modaltabbs">
                                Description
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link
                                eventKey="second"
                                className="modaltabbs"
                              >
                                Product Specs
                              </Nav.Link>
                            </Nav.Item>
                          </Nav>
                        </Col>
                        <Tab.Content>
                          <Tab.Pane
                            eventKey="first"
                            style={{
                              marginTop: "10px",
                              background: "#fff",
                              animationDuration: "0.5s",
                            }}
                          >
                            <a onClick={handleSelect}>
                              <div
                                className="modalprodlongdescription"
                                dangerouslySetInnerHTML={{
                                  __html: product.long_description,
                                }}
                              ></div>
                            </a>
                          </Tab.Pane>
                          <Tab.Pane
                            eventKey="second"
                            style={{
                              marginTop: "10px",
                              background: "#fff",
                              animationDuration: "0.5s",
                            }}
                          >
                            <p>
                              {product.product_specifications ? (
                                <section className="specs-wrp">
                                  <div className="detailspecbox">
                                    <table
                                      width="100%"
                                      border="0"
                                      cellSpacing="2"
                                      cellPadding="0"
                                    >
                                      <tbody>
                                        <tr>
                                          <td>
                                            <table
                                              width="100%"
                                              border="0"
                                              cellSpacing="0"
                                              cellPadding="0"
                                              className="product-spec"
                                              itemProp="description"
                                            >
                                              <tbody>
                                                {product.product_specifications.map(
                                                  function (
                                                    product_specifications,
                                                    index
                                                  ) {
                                                    return (
                                                      <tr key={index}>
                                                        <td className="specs-heading">
                                                          {
                                                            product_specifications
                                                              .product_feature
                                                              .name
                                                          }
                                                        </td>
                                                        <td itemProp="brand">
                                                          {
                                                            product_specifications.feature_value
                                                          }
                                                        </td>
                                                      </tr>
                                                    );
                                                  }
                                                )}
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </section>
                              ) : (
                                ""
                              )}
                            </p>
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                    </div>
                  </Modal>
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : (
            <div className="text-center">No Search Product Found.</div>
          )}
        </>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default SearchResults;
