import React, { useEffect, useRef, useState } from "react";
import BannerFour from "../components/BannerFour";
import BlogAreaFour from "../components/BlogAreaFour";
import BrandAreaOne from "../components/BrandAreaOne";
import FooterThree from "../components/FooterThree";
import NavbarThree from "../components/NavbarThree";
import Exchange from "../components/Exchange/Index";
import ServiceAreaTwo from "../components/ServiceAreaTwo";
import ContactAreaTwo from "../components/ContactAreaTwo";
import FeaturedProduct from "../components/Featured/Index";
import BannerTwo from "../components/BannerTwo";

const HomeFour = () => {
  return (
    <>
      {/* Navigation Bar */}
      {/* <NavbarThree /> */}

      {/* Banner Four */}
      {/* <BannerTwo /> */}
      <BannerFour />

      {/* <ServiceAreaTwo /> */}

      {/* <Exchange /> */}
      {/* <BlogAreaFour /> */}
      <FeaturedProduct />
      <ContactAreaTwo />

      {/* Brand Area One */}
      <BrandAreaOne />

      {/* Footer Three */}
      {/* <FooterThree /> */}
    </>
  );
};

export default HomeFour;
